import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import classnames from "classnames";
import React, { useState, useContext, useEffect } from "react";
import { Loading } from "../components/Loading";
import { Query } from "../lib/api/charts";
import { DealerSelection } from "../Dealer";
import { DashboardTabs } from "./DashboardTabs";
import { DashboardsConfig, DashboardConfig } from "../lib/api/user";
import { DealerContext } from "../contexts/DealerContext";
import { BrandContext } from "../contexts/BrandContext";
import { DashboardConfigContext } from "../contexts/DashboardConfigContext";
import { UserContext } from "../contexts/UserContext";
import { ForecastContext } from "../contexts/ForecastContext";
import { Select } from "../components/Select";
import withWidth, { isWidthUp, WithWidth, WithWidthOptions, isWidthDown } from "@material-ui/core/withWidth";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { Route } from "react-router-dom";
import { margin } from "../style/theme";
import { OemContext } from "../contexts/OemContext";
import { FilterBrandContext } from "../contexts/FilterBrandContext";
import { BenchmarkContext, Benchmark } from "../contexts/BenchmarkContext";
import { PeriodContext } from "../contexts/PeriodContext";
import { BenchmarkSelection } from "../Benchmark";
import { GroupDealerSelection, maxMonth } from "../GroupDealer";
import { GroupDashboardTabs } from "./GroupDashboardTabs";
import { GroupDateContext } from "../contexts/GroupFilterDateContext";
import { useIntl, createIntl } from "react-intl";
import { CustomGroupDealerSelection } from "./dealer-selection/CustomGroupDealer";
import { isAfter, differenceInMonths, endOfMonth } from "date-fns";
import { ExportButtons } from "../components/ExportButtons";
import { DropdownDescription } from "../lib/enum/dropdown-description";
import { RiskDashboardConfigContext } from "../contexts/RiskDashboardConfigContext";
import { OemComparativeDealer } from "../lib/api/flash-reports-config";
import { translation } from "../translations/Translations";
import { PermissionsContext } from "../contexts/PermissionsContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing.unit * 2}px`,
      marginBottom: "50px",
      ["@media (max-width:500px)"]: {
        padding: "5px",
      },
    },
    body: {
      gridArea: "body",
    },
    filterburger: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#555",
    },
    burger: {
      color: "#FFF",
      margin: theme.spacing.unit * 2,
      cursor: "pointer",
    },
    tabs: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      gridArea: "tabs",
      borderTop: "none",
      maxWidth: margin.width,
      margin: "auto",
      backgroundColor: "#555",
    },
    wrapper: {
      backgroundColor: "#555",
    },
    filters: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      },
      gridArea: "filters",
      padding: theme.spacing.unit * 2,
      backgroundColor: "#555",
      color: "#FFF",
      border: "none",
      maxWidth: margin.width,
      margin: "auto",
    },
    chipWrapper: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      },
      gridArea: "filters",
      border: "none",
      maxWidth: margin.width,
      margin: "auto",
      flexBasis: "50%",
    },
    searchInput: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      borderRadius: theme.shape.borderRadius,
      flex: "0 0 auto",
      fontSize: theme.typography.fontSize,
      minWidth: "200px",
      heigh: "auto",
      color: "#333",
      marginTop: theme.spacing.unit,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      marginRight: theme.spacing.unit,
    },
    smallerInput: {
      minWidth: "160px",
    },
    mediumInput: {
      minWidth: "250px",
    },
    hideInput: {},
    menuWrapper: {
      background: "#555",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      flexGrow: 1,
    },
    noFilter: {
      padding: theme.spacing.unit * 0.5,
    },
    chip: {
      margin: theme.spacing.unit,
    },
    placeHolderDiv: {
      width: "100%",
      margin: "auto",
      border: " none",
      maxWidth: "1300px",
      flexBasis: "50%",
    },
    exportBtn: {
      width: "100%",
      maxWidth: margin.width,
      margin: "auto",
      border: "none",
      flexBasis: "50%",
    },
    exportBtnNoFlex: {
      width: "100%",
      margin: "auto",
      border: "none",
    },
    topBar: {
      display: "flex",
      maxWidth: "1300px",
      margin: "auto",
    },
    topBarDashboardType: {
      gridTemplateColumns: "1fr 1fr",
      display: "grid",
      gridColumnGap: "1em",
      maxWidth: "1300px",
      margin: "auto",
    },
  });

export type GroupDashboardOuterRenderProps = {
  query: Query;
  config: DashboardsConfig;
  updateConfig: (key: string, config: DashboardConfig) => void;
};

export type GroupDashboardOuterProps = {
  department: string;
  meta?: any;
  hidePeriod?: boolean;
  render: (props: GroupDashboardOuterRenderProps) => React.ReactNode;
  renderTabs?: (props: GroupDashboardOuterRenderProps) => React.ReactNode;
  filters?: (props: GroupDashboardOuterRenderProps) => React.ReactNode;
  title?: string;
  id?:
    | "metric-table"
    | "group-table"
    | "custom-table"
    | "dashboard-metrics"
    | "group-metrics"
    | "oem-metrics"
    | "custom-metrics"
    | "graph-table-dashboard"
    | "distribution-dashbord";
  displayExport?: boolean;
  showAvgSelector?: boolean;
  oemSpecific?: boolean;
} & WithStyles<typeof styles> &
  WithWidthOptions &
  WithWidth &
  React.HTMLAttributes<HTMLDivElement>;

export const formatFullDate = (date?: Date) => {
  const d = new Date(Date.parse(date.toString()));
  d.setDate(d.getDate() + 1);
  return d.toJSON();
};

const monthDict = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const formatDisplayDate = (date: Date = new Date()) => {
  return `${monthDict[date.getMonth()]} ${date.getFullYear()}`;
};

const months = (date = new Date(2015, 1, 1), end = new Date(), populated: { value: string; label: string }[] = []): { value: string; label: string }[] => {
  const d = new Date(Date.parse(date.toString()));

  populated.push({ value: formatFullDate(d), label: formatDisplayDate(d) });

  if (d.getFullYear() == end.getFullYear() && d.getMonth() == end.getMonth()) {
    return populated;
  }

  d.setDate(1);
  d.setMonth(d.getMonth() + 1);

  return months(d, end, populated);
};

export const addMonths = (date: Date, years: number) => {
  const d = new Date(Date.parse(JSON.stringify(date)));
  d.setMonth(d.getMonth() + years);
  return d;
};

type Option = { value: string; label: string };

const GroupDashboardOuterUnstlyed: React.FunctionComponent<GroupDashboardOuterProps> = ({
  classes,
  className,
  hidePeriod,
  renderTabs,
  department,
  render,
  meta,
  width,
  filters,
  hidden,
  title = "Group Dashboard",
  id = "group-metrics",
  displayExport = true,
  showAvgSelector = false,
  oemSpecific = true,
}) => {
  const intl = useIntl();
  const { selected, selectedDealers, groupMinMonth, groupMaxMonth, loading, childrenDealers } = useContext(DealerContext);
  const { date, updateDate, financialMonth, financialMonthOptions, setFinancialMonth } = useContext(GroupDateContext);
  const { brand, updateBrand } = useContext(FilterBrandContext);
  const brands = useContext(BrandContext);
  const configs = useContext(DashboardConfigContext);
  const forecasts = useContext(ForecastContext);
  const [filtersOpen, setFiltersOpen] = useState(isWidthDown("sm", width) ? true : false);
  const benchmark = useContext(BenchmarkContext);
  const { allowDashboardAvg, loading: permissionsLoading } = useContext(PermissionsContext);
  const { periods, selected: period, setPeriod } = useContext(PeriodContext);
  const [showBenchmarkChip, setShowBenchmarkChip] = useState(true);
  const { user, loading: userLoading } = useContext(UserContext);
  const { comparativeDealers, loading: RiskComparativeLoading } = useContext(RiskDashboardConfigContext);
  const oemSpecificOption = oemSpecific ? selectedDealers.map(d => d.OEMSpecificDash).includes(true) : oemSpecific;

  const [selectedAgg, setSelectedAgg] = React.useState(undefined);

  const noRegion = "no-region";
  const defaultFlashReport: Partial<OemComparativeDealer> = { name: "No Region", oemcode: "", dealercode: "" };
  const [selectedNetworkRegion, setSelectedNetworkRegion] = useState<Partial<OemComparativeDealer>>(defaultFlashReport);

  const formatLabel = (period: string, which: string) => {
    const formattedPeriod = period
      .split(" ")
      .join(".")
      .toLowerCase();
    return intl.formatMessage({ id: `${which}.${formattedPeriod}.label` });
  };

  const formatMonths = (months: any) => {
    return months.reduce((arr, row) => {
      const [month, year] = row.label.split(" ");
      const formattedMonth = formatLabel(month, "month");
      arr.push({ ...row, label: `${formattedMonth.toLowerCase()} ${year}` });
      return arr;
    }, []);
  };

  useEffect(() => {
    if (!loading.loaded && selectedDealers.length > 0) {
      const d = new Date(Date.parse(maxMonth(selectedDealers.map(dealer => dealer.maxMonth)).toString()));
      updateDate({
        value: formatFullDate(d),
        label: formatDisplayDate(d),
      });
    }
  }, [selectedDealers]);

  const availableBrands: Option[] = [
    {
      value: "",
      label: intl.formatMessage({ id: "all.brand.label" }),
    },
    ...(Object.values(
      Object.keys(brands.brands || {}).reduce((avail, code) => {
        const brand = brands.brands[code];
        if (selectedDealers.find(d => d.brands.includes(code))) {
          avail[brand] = { value: code, label: brand };
        }
        return avail;
      }, {}),
    ) as Option[]),
  ];

  const [metadata, setMeta] = useState(meta || {});

  const allForecasts = forecasts.getForecasts();

  const settingsConfig = configs.configs["settings"] || {
    oems: [],
    country: [],
  };

  const regionComparatives = comparativeDealers.filter(
    access => (selectedDealers.map(x => x.oems).flat() || []).includes(access.oemcode) && (settingsConfig.oems || []).includes(access.oemcode),
  );

  useEffect(() => {
    if (regionComparatives.length <= 0) {
      return setSelectedNetworkRegion(defaultFlashReport);
    }
    setSelectedNetworkRegion(regionComparatives[0]);
  }, [selectedDealers]);

  if (
    !loading.loaded ||
    !benchmark.loading.loaded ||
    !configs.loading.loaded ||
    !userLoading.loaded ||
    !permissionsLoading.loaded ||
    RiskComparativeLoading.loading
  ) {
    return <Loading />;
  }

  const query: Query = {
    date: date.value,
    department: department,
    dealers: selectedDealers,
    dealer: selected,
    brand: brands.brands[brand.value],
    forecast: { name: "", type: "", dealers: [], measures: [] },
    benchmarks: benchmark.selected,
    period: period,
    meta: { ...metadata, selectedAgg: selectedAgg },
    financialMonth: financialMonth,
    childDealers: childrenDealers,
    riskMetric: metadata.riskMetric,
    benchmarkDates: (benchmark.selected || [])
      .map(x => x.split(/-(?=[^-]+$)/)[0].trim())
      .reduce((obj, key) => ({ ...obj, [key as any]: benchmark.benchmarkDates[key as any] }), {}),
  };

  const userBrands = availableBrands.filter(brand => {
    if (user.usertype.toLocaleLowerCase() !== "o") {
      return true;
    }

    if (brand.value == "") {
      return true;
    }

    if (user.brands.length === 0) {
      return true;
    }

    return user.brands.includes(brand.value);
  });

  const noDataBms =
    benchmark.selected !== undefined &&
    benchmark.selected[0] != "" &&
    Object.keys(query.benchmarkDates)
      .filter(x => {
        const bmDate = endOfMonth(new Date(query.benchmarkDates[x].maxdate));
        return isAfter(endOfMonth(new Date(query.date)), bmDate) && differenceInMonths(endOfMonth(new Date(query.date)), bmDate) < 6;
      })
      .reduce((memo, val) => {
        const d = new Date(query.benchmarkDates[val].maxdate);
        memo[val] = {
          usedDate: `${monthDict[d.getMonth()]} ${d.getFullYear()}`,
        };
        return memo;
      }, {});

  if (!selected.dealer) {
    return (
      <div style={{ margin: "auto", maxWidth: margin.width, padding: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="headline">
          There are no dealers for your selected filters please go update them <a href="/filters">here</a> and try to view this page again.
        </Typography>
      </div>
    );
  }

  return (
    <>
      {!filtersOpen && isWidthDown("sm", width) && (
        <div className={classes.filterburger}>
          <ExpandMore className={classes.burger} onClick={() => setFiltersOpen(true)} />
        </div>
      )}
      {(filtersOpen || isWidthUp("md", width)) && (
        <div className={classnames(classes.wrapper, "joyride-filters")}>
          <Paper className={classes.filters}>
            <Route
              path="/:route/:dashboard?"
              render={({ match }) => {
                if (match.params.route == "oem" || match.params.dashboard == "dynamic") {
                  return (
                    <GroupDealerSelection
                      dashboard={match.params.dashboard}
                      dealerTypes="all"
                      className={classnames(classes.searchInput, "joyride-filters-dealer")}
                    />
                  );
                }
                if (match.params.route == "group-dashboard" || match.params.route == "oem" || match.params.route == "risk") {
                  return <GroupDealerSelection dashboard={match.params.dashboard} className={classnames(classes.searchInput, "joyride-filters-dealer")} />;
                }
                if (match.params.route == "custom" && (match.params.dashboard == "group" || match.params.dashboard == "distribution")) {
                  return (
                    <CustomGroupDealerSelection dashboard={match.params.dashboard} className={classnames(classes.searchInput, "joyride-filters-dealer")} />
                  );
                }
              }}
            />
            {selectedDealers.length > 0 && (
              <>
                <Select
                  className={classnames(classes.searchInput, classes.smallerInput, classes.hideInput, "joyride-filters-brand")}
                  value={brand.value == "" ? { value: brand.value, label: intl.formatMessage({ id: "all.brand.label" }) } : brand}
                  defaultInputValue={""}
                  placeholder="Brand"
                  onChange={(value: Option) => updateBrand(value)}
                  options={[{ value: "disabled", label: DropdownDescription["BrandSelect"], isdisabled: true }, ...userBrands]}
                />
                {metadata.isRisk && (
                  <Select
                    className={classnames(classes.searchInput, classes.smallerInput)}
                    value={{
                      value: selectedNetworkRegion,
                      label: selectedNetworkRegion.name == noRegion ? "No regions available" : comparableCodeLabel(selectedNetworkRegion),
                    }}
                    onChange={value => setSelectedNetworkRegion(value.value)}
                    options={regionComparatives.map(access => ({ value: access, label: comparableCodeLabel(access) }))}
                  />
                )}
                <Select
                  className={classnames(classes.searchInput, classes.smallerInput)}
                  value={{ value: period, label: intl.formatMessage({ id: translation[period] }) }}
                  onChange={value => setPeriod(value.value)}
                  options={[
                    { value: "disabled", label: DropdownDescription["PeriodSelect"], isdisabled: true },
                    ...periods.map(opt => ({ value: opt, label: intl.formatMessage({ id: translation[opt] }) })),
                  ]}
                />
                {period == "Financial Year" && (
                  <Select
                    className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-financialMonth")}
                    value={{ value: financialMonth, label: monthDict[financialMonth] }}
                    onChange={value => setFinancialMonth(value.value)}
                    options={[
                      { value: "disabled", label: DropdownDescription["FinancialMonthSelect"], isdisabled: true },
                      ...financialMonthOptions.map(opt => ({ value: opt, label: monthDict[opt] })),
                    ]}
                  />
                )}
                <Select
                  className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-month")}
                  value={date}
                  onChange={value => updateDate(value as any)}
                  options={[
                    { value: "disabled", label: DropdownDescription["MonthSelect"], isdisabled: true },
                    ...months(new Date(groupMinMonth), new Date(groupMaxMonth)).reverse(),
                  ]}
                />
                <BenchmarkSelection
                  setBenchmark={benchmark.setBenchmark}
                  benchmark={benchmark.selected}
                  dealers={selectedDealers}
                  hidePeriods={hidePeriod}
                  className={classnames(classes.searchInput, classes.mediumInput)}
                />
                {filters && filters({ query, config: configs.configs, updateConfig: configs.updateConfig })}
              </>
            )}
            {isWidthDown("sm", width) && <ExpandLess className={classes.burger} onClick={() => setFiltersOpen(false)} />}
          </Paper>
          <GroupDashboardTabs dealer={selected} oemSpecificOption={false} className={classes.tabs} />
          {/* <GroupDashboardTabs dealer={selected} oemSpecificOption={selectedDealers.map(d => d.OEMSpecificDash).includes(true)} className={classes.tabs} /> */}
          {metadata.context && (metadata.context == "oem" || metadata.context == "fap") && <DashboardTabs dealer={selected} className={classes.tabs} />}
          {renderTabs && renderTabs({ query, config: configs.configs, updateConfig: configs.updateConfig })}
        </div>
      )}
      <div className={classnames(classes.root, className)}>
        <div className={classes.topBar}>
          {displayExport ? (
            <>
              <ExportButtons
                className={noDataBms && Object.keys(noDataBms).length > 0 && showBenchmarkChip ? classes.exportBtn : classes.exportBtnNoFlex}
                title={title || department}
                id={id}
                filters={{
                  dealer: "",
                  brand: brand.label,
                  date: date.label,
                  forecast: "",
                  period: period,
                  financialMonth: monthDict[financialMonth],
                  benchmarks: benchmark.selected,
                }}
              />
            </>
          ) : (
            <div className={classes.placeHolderDiv} />
          )}
          {allowDashboardAvg && showAvgSelector ? (
            <div>
              <Select
                className={classnames(classes.searchInput, classes.smallerInput)}
                value={selectedAgg == "avg" ? { value: "avg", label: "Average by Dealer Count" } : { value: undefined, label: "Default" }}
                onChange={value => setSelectedAgg(value.value)}
                options={[{ value: undefined, label: "Default" }, { value: "avg", label: "Average by Dealer Count" }, ,]}
              />
            </div>
          ) : (
            <div className={classes.placeHolderDiv} />
          )}
          {!configs.oemSpecificView && displayExport && false && (
            <div>
              <Select
                className={classnames(classes.searchInput)}
                value={configs.dashboardTypeConfig}
                onChange={value => configs.setDashboardTypeConfig(value)}
                options={Object.values(configs.dashboardTypeConfigs)}
              />
            </div>
          )}
          {noDataBms && Object.keys(noDataBms).length > 0 && showBenchmarkChip && (
            <div className={classes.chipWrapper}>
              <Chip
                icon={<InfoIcon />}
                variant="outlined"
                style={{ marginLeft: "auto" }}
                color="primary"
                label={`${Object.keys(noDataBms)
                  .map(x => `${x}: ${noDataBms[x].usedDate}`)
                  .join(" | ")}`}
                onDelete={() => setShowBenchmarkChip(!showBenchmarkChip)}
                className={classes.chip}
              />
            </div>
          )}
        </div>
        {query.date && render({ query, config: configs.configs, updateConfig: configs.updateConfig })}
      </div>
    </>
  );
};

export const GroupDashboardOuter = withStyles(styles)(withWidth()(GroupDashboardOuterUnstlyed));

const comparableCodeLabel = (code: Partial<OemComparativeDealer>) => {
  if (code.oemcode.length == 0 && code.dealercode.length == 0) {
    return code.name;
  }
  return `${code.name} (${code.oemcode})`;
};
