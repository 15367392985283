import * as React from 'react';
import classnames from 'classnames';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";

const styles = (theme: Theme) => createStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "0.5em !important",
    flexWrap: "wrap",
    alignItems: "center",
    flexGrow: 1,
    backgroundColor: "#555",
    color: "#fff",
    border: "none",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    fontFamily: "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif"
  },
});

export type TabberProps = {
  className?: any;
} & WithStyles<typeof styles>;

const TabberUnstlyed: React.SFC<TabberProps> = ({ classes, children, className }) => (
  <Paper square className={classnames(classes.root, className)}>
    {children}
  </Paper>
)

export const Tabber = withStyles(styles)(TabberUnstlyed);
