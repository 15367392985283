import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import classnames from "classnames";
import React, { useState, useContext, useEffect } from "react";
import { Loading } from "../components/Loading";
import { DealerSelection } from "../Dealer";
import { Query } from "../lib/api/charts";
import { DashboardTabs } from "./DashboardTabs";
import { DashboardsConfig, DashboardConfig } from "../lib/api/user";
import { DealerContext, defaultDealer } from "../contexts/DealerContext";
import { BrandContext } from "../contexts/BrandContext";
import { DashboardConfigContext } from "../contexts/DashboardConfigContext";
import { UserContext } from "../contexts/UserContext";
import { ForecastContext } from "../contexts/ForecastContext";
import { Select } from "../components/Select";
import withWidth, { isWidthUp, WithWidth, WithWidthOptions, isWidthDown } from "@material-ui/core/withWidth";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { Route } from "react-router-dom";
import { margin } from "../style/theme";
import { OemContext } from "../contexts/OemContext";
import { DateContext } from "../contexts/FilterDateContext";
import { FilterBrandContext } from "../contexts/FilterBrandContext";
import { BenchmarkContext } from "../contexts/BenchmarkContext";
import { PeriodContext } from "../contexts/PeriodContext";
import { BenchmarkSelection } from "../Benchmark";
import { useIntl } from "react-intl";
import { isAfter, differenceInMonths, endOfMonth } from "date-fns";
import { ExportButtons } from "../components/ExportButtons";
import { DropdownDescription } from "../lib/enum/dropdown-description";
import { translation } from "../translations/Translations";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { CommandPermissions } from "../lib/api/permissions";
import { history } from "../lib/history";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing.unit * 2}px`,
      marginBottom: "50px",
      ["@media (max-width:500px)"]: {
        padding: "5px",
      },
    },
    body: {
      gridArea: "body",
    },
    filterburger: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#555",
    },
    burger: {
      color: "#FFF",
      margin: theme.spacing.unit * 2,
      cursor: "pointer",
    },
    tabs: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      gridArea: "tabs",
      borderTop: "none",
      maxWidth: margin.width,
      margin: "auto",
      backgroundColor: "#555",
    },
    wrapper: {
      backgroundColor: "#555",
    },
    filters: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      },
      gridArea: "filters",
      padding: theme.spacing.unit * 2,
      backgroundColor: "#555",
      color: "#FFF",
      border: "none",
      maxWidth: margin.width,
      margin: "auto",
    },
    chipWrapper: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      },
      gridArea: "filters",
      border: "none",
      maxWidth: margin.width,
      margin: "auto",
      flexBasis: "50%",
    },
    dashboardTypeToggle: {
      marginBottom: "1em",
    },
    placeHolderDiv: {
      width: "100%",
      margin: "auto",
      border: " none",
      maxWidth: "1300px",
      flexBasis: "50%",
    },
    searchInput: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      borderRadius: theme.shape.borderRadius,
      flex: "0 0 auto",
      fontSize: theme.typography.fontSize,
      minWidth: "160px",
      heigh: "auto",
      color: "#333",
      marginTop: theme.spacing.unit,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      marginRight: theme.spacing.unit,
    },
    smallerInput: {
      // minWidth: "160px",
    },
    mediumInput: {
      minWidth: "250px",
    },
    hideInput: {},
    menuWrapper: {
      background: "#555",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      flexGrow: 1,
    },
    noFilter: {
      padding: theme.spacing.unit * 0.5,
    },
    chip: {
      margin: theme.spacing.unit,
    },
    exportBtn: {
      width: "100%",
      maxWidth: margin.width,
      margin: "auto",
      border: "none",
      flexBasis: "50%",
    },
    exportBtnNoFlex: {
      width: "100%",
      margin: "auto",
      border: "none",
    },
    topBar: {
      display: "flex",
      maxWidth: "1300px",
      margin: "auto",
    },
    topBarDashboardType: {
      gridTemplateColumns: "1fr 1fr",
      display: "grid",
      gridColumnGap: "1em",
      maxWidth: "1300px",
      margin: "auto",
    },
    link: {
      color: "blue",
      cursor: "pointer",
    },
  });

export type DashboardOuterRenderProps = {
  query: Query;
  config: DashboardsConfig;
  updateConfig: (key: string, config: DashboardConfig) => void;
};

export type DashboardOuterProps = {
  department: string;
  meta?: any;
  hidePeriod?: boolean;
  onPerformanceAnalysisReport?: boolean;
  multipleBenchmarks?: boolean;
  render: (props: DashboardOuterRenderProps) => React.ReactNode;
  renderTabs?: (props: DashboardOuterRenderProps) => React.ReactNode;
  filters?: (props: DashboardOuterRenderProps) => React.ReactNode;
  benchmarkSelection?: "show" | "hide" | "empty-value";
  selectByTitlesOnly?: boolean;
  dealerTypes?: "consolidated" | "non-consolidated" | "all";
  submissionsTabs?: boolean;
  allowEmptyDealerInit?: boolean;
  title?: string;
  displayExport?: boolean;
  submissions?: boolean;
} & WithStyles<typeof styles> &
  WithWidthOptions &
  WithWidth &
  React.HTMLAttributes<HTMLDivElement>;

export const formatFullDate = (date?: Date) => {
  const d = new Date(Date.parse(date.toString()));
  d.setDate(d.getDate() + 1);
  return d.toJSON();
};

const monthDict = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const formatDisplayDate = (date: Date = new Date()) => {
  return `${monthDict[date.getMonth()]} ${date.getFullYear()}`;
};

const months = (date = new Date(2015, 1, 1), end = new Date(), populated: { value: string; label: string }[] = []): { value: string; label: string }[] => {
  const d = new Date(Date.parse(date.toString()));

  populated.push({ value: formatFullDate(d), label: formatDisplayDate(d) });

  if (d.getFullYear() == end.getFullYear() && d.getMonth() == end.getMonth()) {
    return populated;
  }

  d.setDate(1);
  d.setMonth(d.getMonth() + 1);

  return months(d, end, populated);
};

export const addMonths = (date: Date, years: number) => {
  const d = new Date(Date.parse(date.toJSON()));
  d.setMonth(d.getMonth() + years);
  return d;
};

export const addYear = (date: Date, years: number) => {
  const d = new Date(Date.parse(date.toJSON()));
  d.setFullYear(d.getFullYear() + years);
  return d;
};

type Option = { value: string; label: string };

const DashboardOuterUnstlyed: React.FunctionComponent<DashboardOuterProps> = ({
  classes,
  className,
  hidePeriod,
  renderTabs,
  department,
  render,
  meta,
  width,
  filters,
  hidden,
  onPerformanceAnalysisReport = false,
  multipleBenchmarks = true,
  benchmarkSelection,
  dealerTypes,
  selectByTitlesOnly,
  submissionsTabs = false,
  allowEmptyDealerInit = false,
  title = "Dashboard",
  displayExport = true,
  submissions = false,
}) => {
  const intl = useIntl();
  const dealer = useContext(DealerContext);
  const { date, updateDate, financialMonth, financialMonthOptions, setFinancialMonth } = useContext(DateContext);
  const { brand, updateBrand } = useContext(FilterBrandContext);
  const brands = useContext(BrandContext);
  const configs = useContext(DashboardConfigContext);
  const forecasts = useContext(ForecastContext);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const oems = useContext(OemContext);
  const benchmark = useContext(BenchmarkContext);
  const { periods, selected: period, setPeriod } = useContext(PeriodContext);
  const [showBenchmarkChip, setShowBenchmarkChip] = useState(true);
  const { user, loading: userLoading } = useContext(UserContext);
  const { commandPermissions } = React.useContext(PermissionsContext);
  const canUpload: CommandPermissions[] = commandPermissions.filter(permission => permission.commandName === "UploadData");
  const { canExecute } = canUpload[0];

  const formatLabel = (period: string, which: string) => {
    const formattedPeriod = period
      .split(" ")
      .join(".")
      .toLowerCase();
    return intl.formatMessage({ id: `${which}.${formattedPeriod}.label` });
  };

  const formatMonths = (months: any) => {
    return months.reduce((arr, row) => {
      const [month, year] = row.label.split(" ");
      const formattedMonth = formatLabel(month, "month");
      arr.push({ ...row, label: `${formattedMonth} ${year}` });
      return arr;
    }, []);
  };

  useEffect(() => {
    if (allowEmptyDealerInit && !dealer.selected.isDashboardDealer) {
      setFiltersOpen(true);
      dealer.setDealer(defaultDealer);
    }
  }, []);

  const availableBrands: Option[] = [
    {
      value: "",
      label: intl.formatMessage({ id: "all.brand.label" }),
    },
    ...(Object.values(
      Object.keys(brands.brands || {}).reduce((avail, code) => {
        const brand = brands.brands[code];
        if (dealer.selected.brands.includes(code)) {
          avail[brand] = { value: code, label: brand };
        }
        return avail;
      }, {}),
    ) as Option[]),
  ];

  const [metadata, setMeta] = useState(meta || {});

  const allForecasts = forecasts.getForecasts();
  const availableForecasts = [
    // { label: "All Forecast", value: "" },
    ...(allForecasts || []).reduce((available, forecast) => {
      if (forecast.dealers.includes(dealer.selected.code)) {
        available.push({ value: `${forecast.name}`, label: `${forecast.name} (${forecast.type})` });
      }
      return available;
    }, []),
  ];

  useEffect(() => {
    if (forecasts.loading.loaded && !availableForecasts.find(el => el.label === forecasts.selectedForecast.label)) {
      forecasts.setSelectedForecast(availableForecasts[0] || { value: "", label: "" });
    }
  }, [dealer.selected.code]);

  if (!dealer.loading.loaded || !benchmark.loading.loaded || !configs.loading.loaded || !userLoading.loaded) {
    return <Loading />;
  }

  const query: Query = {
    date: date.value,
    department: department,
    dealers: dealer.selectedDealers,
    dealer: dealer.selected,
    brand: brands.brands[brand.value],
    forecast: (allForecasts || []).find(forecast => forecast.name == forecasts.selectedForecast.value) || { name: "", type: "", dealers: [], measures: [] },
    benchmarks: benchmark.selected,
    period: period,
    meta: metadata,
    financialMonth: financialMonth,
    benchmarkDates: (benchmark.selected || [])
      .map(x => x.split(/-(?=[^-]+$)/)[0].trim())
      .reduce((obj, key) => ({ ...obj, [key as any]: benchmark.benchmarkDates[key as any] }), {}),
  };

  const noDataBms =
    benchmark.selected != undefined &&
    benchmark.selected[0] != "" &&
    Object.keys(query.benchmarkDates)
      .filter(x => {
        const bmDate = endOfMonth(new Date(query.benchmarkDates[x].maxdate));
        return isAfter(endOfMonth(new Date(query.date)), bmDate) && differenceInMonths(endOfMonth(new Date(query.date)), bmDate) < 6;
      })
      .reduce((memo, val) => {
        const d = new Date(query.benchmarkDates[val].maxdate);
        memo[val] = {
          usedDate: `${monthDict[d.getMonth()]} ${d.getFullYear()}`,
        };
        return memo;
      }, {});

  const userBrands = availableBrands.filter(brand => {
    if (user.usertype.toLocaleLowerCase() !== "o") {
      return true;
    }

    if (brand.value == "") {
      return true;
    }

    if (user.brands.length === 0) {
      return true;
    }

    return user.brands.includes(brand.value);
  });

  if (!dealer.selected.dealer && !allowEmptyDealerInit) {
    return (
      <div style={{ margin: "auto", maxWidth: margin.width, padding: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="headline">
          There are no dealers for your selected filters please go update them <a href="/filters">here</a> and try to view this page again.
        </Typography>
      </div>
    );
  }

  const NewDealerText = () => {
    if (dealer.selected.isConsolidated) {
      return <Typography variant="headline">This dealer has no data. This is a consolidated dealer so you cannot perform an upload.</Typography>;
    }

    if (dealer.selected.isChildDealer) {
      return <Typography variant="headline">This dealer has no data. This is a child dealer so you cannot perform an upload.</Typography>;
    }

    return dealer.selected.submissionsWorkbenchEnabled ? (
      <Typography variant="headline">
        This dealer has no data. You may begin the first upload{" "}
        <a className={classes.link} href={`/esos/${dealer.selected.code}/submission/`}>
          here
        </a>
        .
      </Typography>
    ) : (
      <Typography variant="headline">
        This dealer has no data. You may begin the first upload{" "}
        <a className={classes.link} onClick={() => history.push("/submissions/submit")}>
          here
        </a>
        .
      </Typography>
    );
  };

  if (submissionsTabs) {
    return (
      <>
        <div className={classnames(classes.wrapper, "joyride-filters")}>
          {!!dealer.selected.dealer && <DashboardTabs dealer={dealer.selected} className={classes.tabs} />}
          {renderTabs && renderTabs({ query, config: configs.configs, updateConfig: configs.updateConfig })}
        </div>
        <div className={classnames(classes.root, className)}>{render({ query, config: configs.configs, updateConfig: configs.updateConfig })}</div>
      </>
    );
  }

  if (!submissions && dealer.selected.newDealer) {
    return (
      <>
        <div className={classnames(classes.wrapper, "joyride-filters")}>
          <Paper className={classes.filters}>
            <Route
              path="/:route/:dashboard?"
              render={({ match }) => {
                return (
                  <DealerSelection
                    dashboardDealers={match.params.route == "oem" || (match.params.route == "reports" && /Network/.test(match.params.dashboard))}
                    className={classnames(classes.searchInput, "joyride-filters-dealer")}
                    onChange={() => {
                      updateBrand({ value: "", label: "All Brand" });
                      forecasts.setSelectedForecast({ value: "", label: "All Forecast" });
                    }}
                    dealerTypes={dealerTypes}
                  />
                );
              }}
            />
          </Paper>
        </div>
        <div style={{ margin: "auto", maxWidth: margin.width, padding: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
          {canExecute && <NewDealerText />}
        </div>
      </>
    );
  }

  return (
    <>
      {!filtersOpen && isWidthDown("sm", width) && (
        <div className={classes.filterburger}>
          <ExpandMore className={classes.burger} onClick={() => setFiltersOpen(true)} />
        </div>
      )}
      {(filtersOpen || isWidthUp("md", width)) && (
        <div className={classnames(classes.wrapper, "joyride-filters")}>
          <Paper className={classes.filters}>
            <Route
              path="/:route/:dashboard?"
              render={({ match }) => {
                return (
                  <DealerSelection
                    dashboardDealers={match.params.route == "oem" || (match.params.route == "reports" && /Network/.test(match.params.dashboard))}
                    className={classnames(classes.searchInput, "joyride-filters-dealer")}
                    onChange={() => {
                      updateBrand({ value: "", label: "All Brand" });
                      forecasts.setSelectedForecast({ value: "", label: "All Forecast" });
                    }}
                    dealerTypes={dealerTypes}
                  />
                );
              }}
            />
            {!submissionsTabs && (
              <Select
                className={classnames(classes.searchInput, classes.smallerInput, classes.hideInput, "joyride-filters-brand")}
                value={brand.value == "" ? { value: brand.value, label: intl.formatMessage({ id: "all.brand.label" }) } : brand}
                defaultInputValue={""}
                placeholder="Brand"
                onChange={(value: Option) => updateBrand(value)}
                options={[{ value: "disabled", label: DropdownDescription["BrandSelect"], isdisabled: true }, ...userBrands]}
              />
            )}
            {/* <BenchmarkSelection dealers={[dealer.selected]} hidePeriods={hidePeriod} className={classnames(classes.searchInput, classes.mediumInput)} /> */}
            {dealer.selected.hasTargets &&
            dealer.selected.oems.find(oem => (oems.oems.find(o => o.code == oem) || { hasTargets: false }).hasTargets) &&
            availableForecasts.length > 0 ? (
              <>
                <Select
                  className={classnames(classes.searchInput, classes.smallerInput, classes.hideInput, "joyride-filters-forecast")}
                  value={forecasts.selectedForecast}
                  defaultInputValue={""}
                  placeholder="Forecast"
                  onChange={(value: Option) => forecasts.setSelectedForecast(value)}
                  options={[{ value: "disabled", label: DropdownDescription["ForecastSelect"], isdisabled: true }, ...availableForecasts]}
                />
                {!onPerformanceAnalysisReport && (
                  <Select
                    className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-period")}
                    value={{ value: period, label: intl.formatMessage({ id: translation[period] }) }}
                    onChange={value => setPeriod(value.value)}
                    options={[
                      { value: "disabled", label: DropdownDescription["PeriodSelect"], isdisabled: true },
                      ...periods.map(opt => ({ value: opt, label: intl.formatMessage({ id: translation[opt] }) })),
                    ]}
                  />
                )}
                {!onPerformanceAnalysisReport && period == "Financial Year" && (
                  <Select
                    className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-financialMonth")}
                    value={{ value: financialMonth, label: monthDict[financialMonth] }}
                    onChange={value => setFinancialMonth(value.value)}
                    options={[
                      { value: "disabled", label: DropdownDescription["FinancialMonthSelect"], isdisabled: true },
                      ...financialMonthOptions.map(opt => ({ value: opt, label: monthDict[opt] })),
                    ]}
                  />
                )}
                <Select
                  className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-month")}
                  value={date}
                  onChange={value => updateDate(value as any)}
                  options={[
                    { value: "disabled", label: DropdownDescription["MonthSelect"], isdisabled: true },
                    ...formatMonths(months(dealer.selected.minMonth, dealer.selected.maxMonth).reverse()),
                  ]}
                />
                {benchmarkSelection != "hide" && benchmark.selected[0] && (
                  <BenchmarkSelection
                    allowEmptyValue={benchmarkSelection == "empty-value"}
                    dealers={[dealer.selected]}
                    setBenchmark={benchmark.setBenchmark}
                    benchmark={benchmark.selected}
                    hidePeriods={hidePeriod}
                    selectByTitlesOnly={selectByTitlesOnly}
                    allowMultiple={multipleBenchmarks}
                    className={classnames(classes.searchInput, classes.mediumInput)}
                  />
                )}
                {filters && filters({ query, config: configs.configs, updateConfig: configs.updateConfig })}
                {isWidthDown("sm", width) && <ExpandLess className={classes.burger} onClick={() => setFiltersOpen(false)} />}
              </>
            ) : (
              <>
                {!hidePeriod && !onPerformanceAnalysisReport && !submissionsTabs && (
                  <Select
                    className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-period")}
                    value={{ value: period, label: intl.formatMessage({ id: translation[period] }) }}
                    onChange={value => setPeriod(value.value)}
                    options={[
                      { value: "disabled", label: DropdownDescription["PeriodSelect"], isdisabled: true },
                      ...periods.map(opt => ({ value: opt, label: intl.formatMessage({ id: translation[opt] }) })),
                    ]}
                  />
                )}
                {!hidePeriod && !onPerformanceAnalysisReport && !submissionsTabs && period == "Financial Year" && (
                  <Select
                    className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-financialMonth")}
                    value={{ value: financialMonth, label: monthDict[financialMonth] }}
                    onChange={value => setFinancialMonth(value.value)}
                    options={[
                      { value: "disabled", label: DropdownDescription["FinancialMonthSelect"], isdisabled: true },
                      ...financialMonthOptions.map(opt => ({ value: opt, label: monthDict[opt] })),
                    ]}
                  />
                )}
                <Select
                  className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-month")}
                  value={date}
                  onChange={value => updateDate(value as any)}
                  options={[
                    { value: "disabled", label: DropdownDescription["MonthSelect"], isdisabled: true },
                    ...formatMonths(months(dealer.selected.minMonth, dealer.selected.maxMonth).reverse()),
                  ]}
                />
                {benchmarkSelection != "hide" && benchmark.selected[0] && (
                  <BenchmarkSelection
                    allowEmptyValue={benchmarkSelection == "empty-value"}
                    dealers={[dealer.selected]}
                    setBenchmark={benchmark.setBenchmark}
                    benchmark={benchmark.selected}
                    hidePeriods={hidePeriod}
                    selectByTitlesOnly={selectByTitlesOnly}
                    allowMultiple={multipleBenchmarks}
                    className={classnames(classes.searchInput, classes.mediumInput)}
                  />
                )}
                {filters && filters({ query, config: configs.configs, updateConfig: configs.updateConfig })}
                {isWidthDown("sm", width) && <ExpandLess className={classes.burger} onClick={() => setFiltersOpen(false)} />}
              </>
            )}
          </Paper>
          {!!dealer.selected.dealer && <DashboardTabs oemSpecificOption={dealer.selected.OEMSpecificDash} dealer={dealer.selected} className={classes.tabs} />}
          {renderTabs && renderTabs({ query, config: configs.configs, updateConfig: configs.updateConfig })}
        </div>
      )}
      {!!dealer.selected.dealer ? (
        <div className={classnames(classes.root, className)}>
          <div className={classes.topBar}>
            {displayExport ? (
              <ExportButtons
                className={noDataBms && Object.keys(noDataBms).length > 0 && showBenchmarkChip ? classes.exportBtn : classes.exportBtnNoFlex}
                title={title || department}
                id={"dashboard-metrics"}
                filters={{
                  dealer: dealer.selected.dealer,
                  brand: brand.label,
                  date: date.label,
                  forecast: forecasts.selectedForecast.label,
                  period: period,
                  financialMonth: monthDict[financialMonth],
                  benchmarks: benchmark.selected,
                }}
              />
            ) : (
              <div className={classes.placeHolderDiv} />
            )}
            {!configs.oemSpecificView && displayExport && false && (
              <div className={classes.dashboardTypeToggle}>
                <Select
                  className={classnames(classes.searchInput)}
                  value={configs.dashboardTypeConfig}
                  onChange={value => configs.setDashboardTypeConfig(value)}
                  options={Object.values(configs.dashboardTypeConfigs)}
                />
              </div>
            )}
            {noDataBms && Object.keys(noDataBms).length > 0 && showBenchmarkChip && (
              <div className={classes.chipWrapper}>
                <Chip
                  icon={<InfoIcon />}
                  variant="outlined"
                  style={{ marginLeft: "auto" }}
                  color="primary"
                  label={`${Object.keys(noDataBms)
                    .map(x => `${x}: ${noDataBms[x].usedDate}`)
                    .join(" | ")}`}
                  onDelete={() => setShowBenchmarkChip(!showBenchmarkChip)}
                  className={classes.chip}
                />
              </div>
            )}
          </div>
          {render({ query, config: configs.configs, updateConfig: configs.updateConfig })}
        </div>
      ) : (
        <div style={{ margin: "auto", maxWidth: margin.width, padding: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="headline">Please select a dealer.</Typography>
        </div>
      )}
    </>
  );
};

export const DashboardOuter = withStyles(styles)(withWidth()(DashboardOuterUnstlyed));
