import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import React from 'react';
import { FieldRenderProps } from "react-final-form";

const styles = (theme: Theme) => createStyles({
  root: {
    width: "100%",
  },
});

export type InputProps = {

} & FieldRenderProps & WithStyles<typeof styles>;

const TextFieldUnstyled: React.FunctionComponent<InputProps> = ({
  classes,
  input: { name, onChange, value, ...restInput },
  meta,
  ...props
}) => (
  <MuiTextField
    {...props}
    variant="standard"
    className={classnames(classes.root)}
    name={name}
    helperText={meta.touched ? meta.error || meta.submitError : undefined}
    error={(meta.error || (meta.submitError == "" || meta.submitError)) && meta.touched}
    inputProps={restInput}
    onChange={onChange}
    value={value}
  />
)

export const TextField = withStyles(styles)(TextFieldUnstyled);
