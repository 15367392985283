import Button from "@material-ui/core/Button";
import Progress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React, { useState, useContext } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Select } from "../components/Select";
import { TextField } from "../components/TextField";
import { login, resetPassword as apiResetPassword } from "../lib/api/base";
import { history } from "../lib/history";
import logo from "./logo.png";
import road from "./road.jpg";
import { LoginPortal } from "../portals/Login";
import { UserContext } from "../contexts/UserContext";
import { Route } from "react-router";
import { CriteriaContext } from "../contexts/CriteriaContext";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../contexts/LanguageContext";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100%",
      background: `url(${road})`,
      backgroundSize: "cover",
    },
    img: {
      height: "100vh",
      width: "100vw",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "0.1em",
      padding: theme.spacing.unit * 5,
      width: 400,
      height: "100%",
      backgroundColor: "rgba(255,255, 255, 0.8)",
      alignItems: "center",
      justifyContent: "center",
      ["@media (max-width:700px)"]: {
        width: "100%",
      },
    },
    buttons: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 3,
      "& select": { width: "100%" },
    },
    logo: {
      width: 300,
    },
    input: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    languageSelect: {
      width: "100%",
      borderRadius: theme.shape.borderRadius,
      flex: "0 0 auto",
      fontSize: theme.typography.fontSize,
      minWidth: "160px",
      height: "auto",
      color: "#333",
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      marginRight: theme.spacing.unit,
      "& :nth-child(1), :nth-child(2)": {
        backgroundColor: "transparent",
        color: "inherit",
      },
    },
  });

export type LoginProps = {} & WithStyles<typeof styles>;

export const marketsFromSubdomain = {
  meaqa: "af",
  aseanqa: "as",
  australiaqa: "au",
  chinaqa: "cn",
  indiaqa: "in",
  newzealandqa: "nz",
  southafricaqa: "za",
  masterqa: "ma",
  meastaging: "s7",
  aseanstaging: "s1",
  australiastaging: "s4",
  chinastaging: "s3",
  indiastaging: "s5",
  newzealandstaging: "s6",
  southafricastaging: "s2",
  masterstaging: "s8",
  mea: "af",
  asean: "as",
  australia: "au",
  china: "cn",
  india: "in",
  newzealand: "nz",
  southafrica: "za",
  master: "ma",
  mea4: "af",
  asean4: "as",
  australia4: "au",
  china4: "cn",
  india4: "in",
  newzealand4: "nz",
  southafrica4: "za",
  master4: "ma",
};

const setError = ([fieldName, error], state) => {
  if (error !== undefined) {
    state.formState.errors[fieldName] = error;
  } else {
    delete state.formState.errors[fieldName];
  }
  state.fields.email.touched = true;
};

const announcementdata = {
  asean: {
    support: "insightbmteam@msxi.com",
    url: "https://asean.msxinsightbm.com/",
  },
  southafrica: {
    support: "insightbmteamsa@msxi.com",
    url: "https://southafrica.msxinsightbm.com/",
  },
  china: {
    support: "insightbmteamcn@msxi.com",
    url: "https://china.msxinsightbm.com/",
  },
  india: {
    support: "insightbmteamin@msxi.com",
    url: "https://india.msxinsightbm.com/",
  },
  australia: {
    support: "insightbmteamau@msxi.com",
    url: "https://australia.msxinsightbm.com/",
  },
  newzealand: {
    support: "insightbmteamnz@msxi.com",
    url: "https://newzealand.msxinsightbm.com/",
  },
};

const LoginUnstlyed: React.FunctionComponent<LoginProps> = ({ classes, children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [credentials, setCredentials] = useState<{ email: string; password: string }>({ email: "", password: "" });
  const { criteria, hydrate: hydrateCriteria, loading: loadingCriteria } = useContext(CriteriaContext);
  const { user, hydrate, loading } = useContext(UserContext);
  const { language, updateUserLanguage, languageOptions, updateLanguageConfig } = useContext(LanguageContext);
  const intl = useIntl();
  const [requireActivation, setRequireActivation] = useState<boolean>(false);
  const [uName, setUsername] = useState<string>("");
  const [market, setMarket] = useState<string>("");
  const [resettingPassword, setResettingPassword] = useState<boolean>(false);

  const resetPassword = async (form: any) => {
    const result = await apiResetPassword(uName, market);
    form.mutators.setError("email", result.message);
    setResettingPassword(false);
  };

  if (loggedIn && loading.loaded && loadingCriteria.loaded) {
    return (
      <LoginPortal>
        <form
          method="POST"
          target="federated-login"
          action={user.modules.login}
          ref={ref => {
            ref && ref.submit();
          }}
        >
          <input type="text" name="username" value={credentials.email} />
          <input type="password" name="password" value={credentials.password} />
          <button>Login</button>
        </form>
        <iframe
          name="federated-login"
          title="federated-login"
          data-test-id="federated login iframe"
          onLoad={() => {
            if (criteria.oems.length >= 2) {
              history.push("/filters");
            } else {
              history.push("/dashboard");
            }
          }}
        />
      </LoginPortal>
    );
  }

  return (
    <div className={classes.container}>
      <Paper className={classnames(classes.root)}>
        <Route
          path="/login"
          render={({ match }) => (
            <Form
              initialValues={{ market: marketsFromSubdomain[window.location.host.split(".")[0].split("-")[0]] }}
              onSubmit={async (fields: any) => {
                try {
                  const user = await login(fields);
                  if (user.message == "You need to provide an activation token") {
                    setRequireActivation(true);
                    return {
                      activationcode: intl.formatMessage({ id: "login.page.require.activation" }),
                    };
                  }
                  if (user.message == "Activation token still valid") {
                    setRequireActivation(true);
                    return {
                      activationcode: intl.formatMessage({ id: "login.page.require.activation_still_valid" }),
                    };
                  }
                  if (requireActivation && user.message == "Invalid credentials") {
                    return {
                      activationcode: intl.formatMessage({ id: "login.page.invalid.activation.code" }),
                    };
                  }
                  if (user.message == "Account Locked. Please Contact Support.") {
                    return {
                      email: "",
                      password: intl.formatMessage({ id: "login.page.lockedout" }),
                    };
                  }
                  if (user.message == "You have not logged on for a long time, as a result your account has been locked.") {
                    return {
                      email: "",
                      password: intl.formatMessage({ id: "login.page.lockedout.aged" }),
                    };
                  }
                  if (user.message) {
                    throw Promise.reject("Failure to login");
                  }
                  await Promise.all([hydrateCriteria(), hydrate()]);
                  await updateLanguageConfig(language);
                  setCredentials(fields);
                  setLoggedIn(true);
                } catch (e) {
                  return {
                    email: "",
                    password: intl.formatMessage({ id: "login.page.login.error" }),
                  };
                }
              }}
              mutators={{ setError }}
              render={({ form, values, dirty, touched, handleSubmit, submitting }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <FormSpy
                      onChange={props => {
                        setUsername(props.values.email);
                        setMarket(props.values.market);
                      }}
                    />
                    <div className={classes.buttons}>
                      <img className={classes.logo} src={logo} />
                    </div>
                    <div className={classes.inputs}>
                      {(dirty ? touched.market : !values.market) && (
                        <Field className={classes.input} name="market" label="Market" component="select">
                          <option value="">Select a market</option>
                          <option value="af">Middle East & Africa</option>
                          <option value="as">Asean"</option>
                          <option value="au">Australia</option>
                          <option value="cn">China</option>
                          <option value="in">India</option>
                          <option value="nz">New Zealand</option>
                          <option value="za">South Africa</option>
                          <option value="ma">Master</option>
                        </Field>
                      )}
                      {!requireActivation && (
                        <Field className={classes.input} name="email" label={<FormattedMessage id="login.page.username.label" />} component={TextField} />
                      )}
                      {!requireActivation && (
                        <Field
                          className={classes.input}
                          name="password"
                          label={<FormattedMessage id="login.page.password.label" />}
                          type="password"
                          component={TextField}
                        />
                      )}
                      {requireActivation && (
                        <Field
                          className={classes.input}
                          name="activationcode"
                          label={<FormattedMessage id="login.page.activation.code.label" />}
                          component={TextField}
                        />
                      )}
                      <Select
                        name="language"
                        className={classnames(classes.languageSelect)}
                        placeholder="Language"
                        value={languageOptions.find(lang => lang.value === language)}
                        onChange={(language: { value: string; label: string }) => updateUserLanguage(language.value)}
                        options={languageOptions}
                      />
                    </div>
                    <div className={classes.buttons}>
                      <Button disabled={submitting || loggedIn || resettingPassword} variant="contained" color="primary" type="submit">
                        {(submitting || loggedIn) && <Progress size={12} />}
                        <FormattedMessage id="login.page.login.button" />
                      </Button>
                      <Button
                        disabled={submitting || loggedIn || resettingPassword}
                        variant="text"
                        color="secondary"
                        onClick={() => {
                          if (!!uName) {
                            setResettingPassword(true);
                            resetPassword(form);
                          } else {
                            form.mutators.setError("email", "Cant be blank. Please enter your username to reset your password");
                          }
                        }}
                      >
                        {resettingPassword && <Progress size={12} />}
                        <FormattedMessage id="login.page.reset.password.button" />
                      </Button>
                    </div>
                  </form>
                </>
              )}
            />
          )}
        />
      </Paper>
    </div>
  );
};

export const Login = withStyles(styles)(LoginUnstlyed);

export default Login;
