import Divider from "@material-ui/core/Divider";
import MuiDashboardDrawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Dashboard from "@material-ui/icons/Dashboard";
import classnames from "classnames";
import React from "react";
import { Route } from "react-router-dom";
import { history } from "../lib/history";
import { drawerWidth, toolbar } from "./variables";
import { locations } from "./Layout";
import { TabAccessContext } from "../contexts/TabAccessContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing.unit * 7,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing.unit * 9,
      },
    },
    toolbar: toolbar(theme),
    selected: {
      backgroundColor: theme.palette.background.paper,
    },
    hideToolbar: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
      [theme.breakpoints.up("xl")]: {
        display: "block",
      },
    },
  });

export type DashboardDrawerProps = {
  open: boolean;
  className?: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
} & WithStyles<typeof styles>;

type ItemProps = {
  label?: string;
  children?: React.ReactElement<any>;
  location?: string;
  match?: any;
  onClick?(e?: any): void;
  disabled?: boolean;
} & WithStyles<typeof styles>;

const Item: React.FunctionComponent<ItemProps> = ({ children, onClick, match, location, label, disabled }) => (
  <ListItem
    disabled={disabled}
    selected={match.url == location || false}
    button
    onClick={() => {
      history.push(location);
      onClick();
    }}
  >
    {!disabled && (
      <Tooltip title={label} placement="right">
        <ListItemIcon>{children}</ListItemIcon>
      </Tooltip>
    )}
    <ListItemText primary={label} />
  </ListItem>
);

// TODO: determine worthiness.
// const SubItem: React.FunctionComponent<ItemProps> = ({ children, label, location, match, classes }) => (
//   <ListItem
//     selected={match.url.includes(location)}
//     button
//     onClick={() => history.push(location)}
//     classes={{
//       selected: classes.selected
//     }}>
//     <ListItemIcon>
//       {children}
//     </ListItemIcon>
//     <ListItemText primary={label} />
//   </ListItem>
// );

const DashboardDrawerUnstlyed: React.FunctionComponent<DashboardDrawerProps> = ({ classes, className, children, open, onClick }) => {
  const { tabs: accessTabs, loading } = React.useContext(TabAccessContext);

  const availLocations = Object.values(accessTabs).map((tab: any) => tab.name);

  return (
    <MuiDashboardDrawer
      variant="temporary"
      className={className}
      classes={{
        paper: classnames(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={onClick}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <Item label={"Dashboard"} match={"dash-item"} classes={classes} disabled={true} />
      <Route
        render={({ match }) =>
          locations.dashboard.children.map(dash => {
            if ((/OEM/.test(dash.label) && availLocations.includes(dash.id)) || /Custom/.test(dash.label)) {
              return (
                <span className={classes.hideToolbar}>
                  <Item onClick={onClick} match={match} label={dash.label} location={dash.to} classes={classes}>
                    <Dashboard />
                  </Item>
                </span>
              );
            }
            if (/Home/.test(dash.id) || availLocations.includes(dash.id)) {
              return (
                <Item onClick={onClick} match={match} label={dash.label} location={dash.to} classes={classes}>
                  <Dashboard />
                </Item>
              );
            }
            return null;
          })
        }
      />
      <Divider />
      <Item label={"Group Dashboard"} match={"group-item"} classes={classes} disabled={true} />
      <Route
        render={({ match }) =>
          locations.groupDashboard.children.map(group => {
            if (availLocations.includes(group.id)) {
              return (
                <Item onClick={onClick} match={match} label={group.label} location={group.to} classes={classes}>
                  <Dashboard />
                </Item>
              );
            }
            return null;
          })
        }
      />
      <Divider />
      <Item label={"Custom Dashboards"} match={"custom-dash-item"} classes={classes} disabled={true} />
      <Route
        render={({ match }) =>
          locations.customDashboard.children.map(group => {
            if (availLocations.includes(group.id)) {
              return (
                <Item onClick={onClick} match={match} label={group.label} location={group.to} classes={classes}>
                  <Dashboard />
                </Item>
              );
            }
            return null;
          })
        }
      />
    </MuiDashboardDrawer>
  );
};

export const DashboardDrawer = withStyles(styles)(DashboardDrawerUnstlyed);
