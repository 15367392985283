import React, { createContext, useState, useMemo, useEffect } from "react";
import { formatFullDate, formatDisplayDate } from "../charts/DashboardOuter";
import { subMonths } from "date-fns";

const defaultDate = new Date(2018, 11, 1);

const staticFinancialMonths: number[] = Array.from(Array(12).keys());

export type DateContextState = {
  date: any;
  financialMonth: number;
  financialMonthOptions: number[];
  updateDate(newDate: any);
  setFinancialMonth: (month: number) => void;
};

export const DateContext = createContext<DateContextState>({
  date: defaultDate,
  financialMonth: 0,
  financialMonthOptions: staticFinancialMonths,
  updateDate: () => {},
  setFinancialMonth: () => {},
});

export const DateContextProvider = ({ children }) => {
  const [date, setDate] = useState({ value: formatFullDate(defaultDate), label: formatDisplayDate(defaultDate), maxMonth: "" });
  const [financialMonth, setFinancialMonth] = useState<number>(0);
  const [financialMonthOptions, setFinancialMonthOptions] = useState<number[]>(staticFinancialMonths);

  useEffect(() => {
    setFinancialMonthOptions(staticFinancialMonths.map(number => subMonths(defaultDate, number).getMonth()));
  }, []);

  const value = useMemo(
    () => ({
      date,
      financialMonthOptions,
      financialMonth,
      setFinancialMonth,
      updateDate: (newDate: any) => {
        try {
          setDate(newDate);
          const newStaticMonths = staticFinancialMonths.map(number => subMonths(new Date(newDate.value), number).getMonth());
          setFinancialMonthOptions(newStaticMonths);
          setFinancialMonth(newStaticMonths[0]);
        } catch (e) {
          return e;
        }
      },
      setDate,
    }),
    [date, financialMonth, financialMonthOptions],
  );

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
};
