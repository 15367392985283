import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React, { useState, useContext } from "react";
import { Report } from "../../lib/api/charts";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { DashboardConfigContext } from "../../contexts/DashboardConfigContext";
import { GroupDashboard } from "../../charts/GroupDashboard";
import { GroupMetricCard } from "../../charts/GroupMetricCard";
import { SelectableChart as SelectableSeriesChart } from "../../charts/GroupSeriesChart";
import { SelectableChart as SelectableBarChart } from "../../charts/GroupStackedChart";

const styles = (theme: Theme) =>
  createStyles({
    menuItem: {
      width: "250px",
      height: "50px",
    },
  });

export type GroupBalanceSheetDashboardProps = {} & WithStyles<typeof styles>;

const chartOptions = {
  currentratio: "CURRENT RATIO",
  workingcapitaldays: "WORKING CAPITAL DAYS",
  workingcapitalamount: "WORKING CAPITAL AMOUNT",
  cashconversioncycle: "CASH CONVERSION CYCLE",
  totaldebtordays: "TOTAL DEBTOR DAYS",
  totalinventorydays: "TOTAL INVENTORY DAYS",
  debttoequity: "DEBT to EQUITY",
  roi: "ROI",
};

const GroupBalanceSheetDashboardUnstlyed: React.FunctionComponent<GroupBalanceSheetDashboardProps> = ({ classes, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { updateResetAllDashboards } = useContext(DashboardConfigContext);
  return (
    <GroupDashboard
      department="All Department"
      title="Balance Sheet"
      metrics={({ query }) => (
        <>
          <GroupMetricCard metric="currentratio" query={query} title={"metric.card.current.ratio.label"} />
          <GroupMetricCard metric="workingcapitaldays" query={query} title={"metric.card.working.capital.days.label"} />
          <GroupMetricCard metric="workingcapitalamount" query={query} title={"metric.card.working.capital.amount.label"} />
          <GroupMetricCard metric="cashconversioncycle" query={query} title={"metric.card.cash.conversion.cycle.label"} />
          <GroupMetricCard metric="totaldebtordays" query={query} title={"metric.card.total.debtor.days.label"} />
          <GroupMetricCard metric="totalinventorydays" query={query} title={"metric.card.total.inventory.days.label"} />
          <GroupMetricCard metric="debttoequity" query={query} title={"metric.card.debt.to.equity.label"} />
          <GroupMetricCard metric="roi" query={query} title={"metric.card.roi.label"} />
        </>
      )}
      filters={({ query, config, updateConfig }) => (
        <div>
          <Button onClick={e => setAnchorEl(e.currentTarget)} variant="contained" style={{ backgroundColor: "#fff", marginLeft: 10, marginTop: 10 }}>
            Reset
          </Button>
          <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                updateConfig("Balance Sheet", {
                  dashboard: "Balance Sheet",
                  items: {
                    tr: { measure: "workingcapitaldays" },
                    br: { measure: "debttoequity" },
                  },
                });
                setAnchorEl(null);
              }}
            >
              Reset Balance Sheet
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                updateResetAllDashboards();
                setAnchorEl(null);
              }}
            >
              Reset All
            </MenuItem>
          </Menu>
        </div>
      )}
      grid={({ query }) => <></>}
      charts={({ query, config, updateConfig }) => {
        const execConfig = config["Balance Sheet"] || {
          dashboard: "Balance Sheet",
          items: {
            tr: { measure: "currentratio" },
            br: { measure: "workingcapitaldays" },
          },
        };
        const update = (key: string) => (report: Report) => {
          updateConfig("Balance Sheet", {
            ...execConfig,
            items: {
              ...execConfig.items,
              [key]: { measure: report },
            },
          });
        };
        return (
          <>
            <SelectableSeriesChart
              handleSelection={update("tr")}
              report={execConfig.items.tr.measure}
              title="Working Capital Days"
              query={query}
              showBenchmark
              showTarget
              chartOptions={chartOptions}
            />
            <SelectableBarChart
              handleSelection={update("br")}
              report={execConfig.items.br.measure}
              title="DEBT : EQUITY"
              query={query}
              showBenchmark
              showTarget
              chartOptions={chartOptions}
            />
          </>
        );
      }}
    />
  );
};

export const GroupBalanceSheetDashboard = withStyles(styles)(GroupBalanceSheetDashboardUnstlyed);

export default GroupBalanceSheetDashboard;
