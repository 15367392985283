import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";
import { Query } from "../lib/api/charts";
import { DashboardOuter } from "./DashboardOuter";
import { DashboardsConfig, DashboardConfig } from "../lib/api/user";
import { margin } from "../style/theme";
import { GroupDashboardOuter } from "./GroupDashboardOuter";
import Typography from "@material-ui/core/Typography";
import { ExportButtons } from "../components/ExportButtons";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      width: "100%",
      maxWidth: margin.width,
      margin: "auto",
      gridTemplateAreas: `
      "metrics"
      "charts"
    `,
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto 1fr",
      gridGap: `${theme.spacing.unit * 2}px`,
      [theme.breakpoints.up("md")]: {},
    },
    reverseRoot: {
      display: "grid",
      width: "100%",
      maxWidth: margin.width,
      margin: "auto",
      gridTemplateAreas: `
      "charts"
      "metrics"
    `,
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto 1fr",
      gridGap: `${theme.spacing.unit * 2}px`,
      [theme.breakpoints.up("md")]: {},
    },
    table: {
      gridArea: "table",
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "repeat(2, min-content)",
    },
    metrics: {
      display: "grid",
      gridArea: "metrics",
      // gridTemplateRows: "340px 340px",
      gridTemplateColumns: "1fr",
      gridGap: `${theme.spacing.unit * 2}px`,

      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "1fr 1fr",
      },

      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "1fr 1fr 1fr",
      },

      [theme.breakpoints.up("lg")]: {
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
      },
    },
    charts: {
      gridArea: "charts",
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "374px",
      gridGap: `${theme.spacing.unit * 2}px`,

      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "1fr 1fr",
      },
    },
    selectDealersMsg: {
      display: "grid",
      width: "100%",
      maxWidth: margin.width,
      margin: "auto",
      padding: "20px",
    },
    message: {
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontSize: "14px",
      color: "#555",
    },
    exportBtn: {
      width: "100%",
      maxWidth: margin.width,
      margin: "auto",
    },
  });

export type GroupDashboardRenderProps = {
  query: Query;
  config: DashboardsConfig;
  updateConfig: (key: string, config: DashboardConfig) => void;
};

export type GroupDashboardProps = {
  dashboard?: "dashboard" | "oem" | "oem-reverse";
  department: string;
  metrics: (props: GroupDashboardRenderProps) => React.ReactNode;
  grid: (props: GroupDashboardRenderProps) => React.ReactNode;
  charts: (props: GroupDashboardRenderProps) => React.ReactNode;
  meta?: any;
  filters?: (props: GroupDashboardRenderProps) => React.ReactNode;
  title?: string;
  showAvgSelector?: boolean;
} & WithStyles<typeof styles>;

const GroupDashboardUnstlyed: React.FunctionComponent<GroupDashboardProps> = ({
  classes,
  dashboard = "dashboard",
  department,
  metrics,
  grid,
  charts,
  filters,
  meta,
  title,
  showAvgSelector = false,
}) => {
  if (dashboard == "oem-reverse") {
    return (
      <GroupDashboardOuter
        department={department}
        filters={filters}
        meta={meta}
        title={title}
        render={rProps =>
          rProps.query.dealers.length > 0 ? (
            <div className={classnames(classes.reverseRoot)} id={"group-metrics"}>
              <div className={classes.metrics}>{metrics(rProps)}</div>
              <div className={classes.charts}>{charts(rProps)}</div>
            </div>
          ) : (
            <span className={classes.selectDealersMsg}>
              <Typography className={classes.message}>Please select some dealers</Typography>
            </span>
          )
        }
      />
    );
  }
  return (
    <GroupDashboardOuter
      department={department}
      filters={filters}
      meta={meta}
      title={title}
      showAvgSelector={showAvgSelector}
      render={rProps =>
        rProps.query.dealers.length > 0 ? (
          <div className={classnames(classes.root)} id={"group-metrics"}>
            <div className={classes.metrics}>{metrics(rProps)}</div>
            <div className={classes.charts}>{charts(rProps)}</div>
          </div>
        ) : (
          <span className={classes.selectDealersMsg}>
            <Typography className={classes.message}>Please select some dealers</Typography>
          </span>
        )
      }
    />
  );
};

export const GroupDashboard = withStyles(styles)(GroupDashboardUnstlyed);
