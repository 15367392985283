import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { MenuItem } from "./MenuItem";
import { Tabber } from "./Tabber";
import { Dealer } from "../lib/api/charts";
import CircularProgress from "@material-ui/core/CircularProgress";
import { locations } from "../layout/Layout";
import { TabAccessContext } from "../contexts/TabAccessContext";
import { translation } from "../translations/Translations";
import { useIntl } from "react-intl";
import { DashboardConfigContext } from "../contexts/DashboardConfigContext";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
      width: "100%",
      flexDirection: "row",
      marginTop: "0.5em !important",
      flexWrap: "wrap",
      alignItems: "center",
      flexGrow: 1,
      backgroundColor: "#555",
      color: "#fff",
      border: "none",
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    tab: {
      padding: theme.spacing.unit * 2,
      borderTopRightRadius: "3px",
      borderTopLeftRadius: "3px",
    },
    selected: {
      backgroundColor: "#fff",
      color: "#555",
      border: "none",
      boxShadow: "none",
    },
  });

export type GroupDashboardTabsProps = {
  className: any;
  dealer: Dealer;
  oemSpecificOption?: boolean;
} & WithStyles<typeof styles>;

const GroupDashboardTabsUnstlyed: React.FunctionComponent<GroupDashboardTabsProps> = ({ classes, dealer, oemSpecificOption = false, children, className }) => {
  const { oemSpecificView, updateOemSpecificView } = useContext(DashboardConfigContext);
  const { tabs: accessTabs, loading } = React.useContext(TabAccessContext);

  if (loading.loading) {
    <CircularProgress size={15} />;
  }

  const availLocations = Object.values(accessTabs).map((tab: any) => tab.name);

  const intl = useIntl();

  return (
    <Tabber className={classnames(classes.root, className)}>
      <Route
        path="/group-dashboard/:department?"
        render={({ match }) =>
          locations.groupDashboard.children.map(location => {
            if (/Home/.test(location.id) || availLocations.includes(location.id)) {
              return (
                <MenuItem to={location.to} key={location.label} active={match.url == location.to} count={location.count} variant="dashboard">
                  {translation[location.label] ? intl.formatMessage({ id: translation[location.label] }) : location.label}
                </MenuItem>
              );
            }
            return null;
          })
        }
      />
      {oemSpecificOption && <div>
        <FormControlLabel
          control={
            <Switch checked={oemSpecificView} onChange={(event) => updateOemSpecificView(event.target.checked)} />
          }
          label={oemSpecificView ? <Typography style={{ color: "white" }}>OEM View</Typography> : <Typography style={{ color: "white" }}>Standard View</Typography>}
        />

      </div>}
    </Tabber>
  );
};

export const GroupDashboardTabs = withStyles(styles)(GroupDashboardTabsUnstlyed);
