import * as React from "react";
import BaseSelect from "react-select";
import { useIntl } from "react-intl";

export const Select = ({ styles = {}, ...props }) => {
  const intl = useIntl();
  return (
    <BaseSelect
      {...props}
      placeholder={intl.formatMessage({ id: "select.placeholder.text" })}
      styles={{
        control: base => ({ ...base, minHeight: "35px" }),
        dropdownIndicator: base => ({ ...base, padding: "5px" }),
        ...styles,
      }}
      isOptionDisabled={(option: any) => option.isdisabled}
    />
  );
};

export const SelectAll = ({ styles = {}, allOption = { label: "Select All", value: "*" }, value, options, onChange, ...props }) => {
  if (value.length === options.length) {
    return (
      <BaseSelect
        {...props}
        options={options}
        styles={{
          control: base => ({ ...base, minHeight: "20px" }),
          dropdownIndicator: base => ({ ...base, padding: "5px" }),
          ...styles,
        }}
        value={[allOption]}
        onChange={(selected: any) => {
          onChange(selected.slice(1));
        }}
      />
    );
  }
  return (
    <BaseSelect
      {...props}
      value={value}
      styles={{
        control: base => ({ ...base, minHeight: "20px" }),
        dropdownIndicator: base => ({ ...base, padding: "5px" }),
        ...styles,
      }}
      options={[allOption, ...options]}
      onChange={(selected: any) => {
        if (selected.length > 0 && selected[selected.length - 1].value === allOption.value) {
          return onChange(options);
        }
        return onChange(selected);
      }}
    />
  );
};
