import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      position: "relative",
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    active: {
      backgroundColor: "#555",
      color: "#fff",
      border: "none",
    },
    link: {
      textDecoration: "none",
      color: "inherit",
      fontSize: `${theme.typography.fontSize}px`,
      padding: theme.spacing.unit * 2,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
      borderTopRightRadius: "3px",
      borderTopLeftRadius: "3px",
      display: "block",
      minWidth: "135px",
      textAlign: "center",
    },
    indicator: {
      borderRadius: "50%",
      backgroundColor: "crimson",
      width: "1rem",
      height: "1rem",
      fontSize: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#FFF",
      position: "absolute",
      left: "100%",
      transform: "translate(-50%, -50%)",
      zIndex: 999,
    },
  });

export type MenuItemProps = {
  active?: boolean;
  to: string;
  count?: number;
  className?: string;
} & WithStyles<typeof styles>;

const MenuItemUnstlyed: React.FunctionComponent<MenuItemProps> = ({ className, classes, active, to, children, count }) => {
  return (
    <Typography
      variant="title"
      color="inherit"
      className={classnames(classes.root, className, {
        [classes.active]: active,
      })}
    >
      {count && count > 0 ? <div className={classes.indicator} /> : null}

      <Link to={to} className={classes.link} key={to}>
        {children}
      </Link>
    </Typography>
  );
};

export const MenuItem = withStyles(styles)(MenuItemUnstlyed);
