import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { NativeSelectProps } from "@material-ui/core/NativeSelect";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { Select } from "./components/Select";
import { DashboardConfigContext } from "./contexts/DashboardConfigContext";
import { DealerContext } from "./contexts/DealerContext";
import { DealerFilter, filterDealers } from "./DealerFilter";
import classnames from "classnames";
import { CriteriaContext } from "./contexts/CriteriaContext";
import { OemContext } from "./contexts/OemContext";
import { DropdownDescription } from "./lib/enum/dropdown-description";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    style: {
      padding: theme.spacing.unit * 2,
      minWidth: 400,
      backgroundColor: "whitesmoke",
      display: "flex",
      flexDirection: "column",
    },
    fields: {},
    control: {
      marginTop: theme.spacing.unit * 2,
      width: "100%",
    },
    input: {
      marginTop: theme.spacing.unit * 2,
      backgroundColor: "white",
    },
    title: {
      fontWeight: "bold",
      fontSize: theme.typography.fontSize * 1.2,
    },
    dealerList: {
      overflow: "hidden",
      overflowY: "scroll",
    },
    button: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      height: "35px",
      backgroundColor: "#fff",
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    formLabelText: {
      paddingBottom: theme.spacing.unit * 1,
      fontSize: "0.8rem",
    },
    tableGrid: {
      marginTop: "20px",
      height: "200px",
      overflowY: "scroll",
      border: "1px solid #ddd",
    },
    mainModal: {
      maxHeight: "100%",
      height: "100%",
      maxWidth: "600px",
    },
    actionButtons: {
      float: "right",
    },
    tableHeader: {
      backgroundColor: "white",
      fontSize: theme.typography.fontSize * 1,
    },
    tableBody: {
      backgroundColor: "white",
    },
    clickable: {
      cursor: "pointer",
    },
    inputText: {
      backgroundColor: "white",
      padding: 9,
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    innerInputLabel: {
      transform: "translate(12px, 11px) scale(1)",
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    selectInputs: {
      fontSize: `${theme.typography.fontSize + 2}px`,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    cell: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      fontSize: "0.8rem",
    },
    hr: {
      width: "95%",
      marginTop: 15,
    },
    code: {
      width: 100,
    },
    cellHead: {
      backgroundColor: "#f3f3f3",
    },
    selectInput: {
      padding: 0,
    },
    dealerSelection: {
      minWidth: "200px !important",
    },
  });

export type DealerSelectionProps = {
  onChange?(val: any): void;
  dashboardDealers?: boolean;
  dealerTypes?: "consolidated" | "non-consolidated" | "all";
  submissionsWorkbenchEnabled?: boolean;
  excludeSubmissionsWorkbenchEnabled?: boolean;
} & WithStyles<typeof styles>;

const DealerSelectionUnstlyed: React.SFC<DealerSelectionProps & NativeSelectProps> = ({
  classes,
  children,
  dashboardDealers = false,
  onChange,
  dealerTypes,
  submissionsWorkbenchEnabled = false,
  excludeSubmissionsWorkbenchEnabled,
  ...props
}) => {
  const [modalOpen, toggleModal] = React.useState(false);
  const { configs } = React.useContext(DashboardConfigContext);
  const {
    setDealer,
    available,
    selected,
    setDealerCode,
    setDealerName,
    setFilters,
    setFilterSet,
    dealerCode,
    dealerName,
    filterSet,
    filters,
  } = React.useContext(DealerContext);
  const settings = configs.settings || { oems: [], country: [] };
  const { oems, loading: oemLoading } = React.useContext(OemContext);

  const availableOemsByCountry = oems.filter(oem => filters.country.includes(oem.country));

  const updateFilter = (which: string) => e => {
    setFilters({
      ...filters,
      [which]: e.map(v => v.value),
    });
  };

  const filteredDealers = React.useMemo(() => {
    if (dealerTypes) {
      return filterDealers(
        available,
        filterSet.dealerCode,
        filterSet.dealerName,
        filterSet.filters,
        dashboardDealers,
        dealerTypes,
        submissionsWorkbenchEnabled,
        excludeSubmissionsWorkbenchEnabled,
        settings,
        availableOemsByCountry,
      );
    }
    return filterDealers(
      available,
      filterSet.dealerCode,
      filterSet.dealerName,
      filterSet.filters,
      dashboardDealers,
      "all",
      submissionsWorkbenchEnabled,
      excludeSubmissionsWorkbenchEnabled,
      settings,
      availableOemsByCountry,
    );
  }, [filterSet, dashboardDealers, availableOemsByCountry]);

  const liveFilteredDealers = React.useMemo(() => {
    if (dealerTypes) {
      return filterDealers(
        available,
        dealerCode,
        dealerName,
        filters,
        dashboardDealers,
        dealerTypes,
        submissionsWorkbenchEnabled,
        excludeSubmissionsWorkbenchEnabled,
        settings,
        availableOemsByCountry,
      );
    }
    return filterDealers(
      available,
      dealerCode,
      dealerName,
      filters,
      dashboardDealers,
      "all",
      submissionsWorkbenchEnabled,
      excludeSubmissionsWorkbenchEnabled,
      settings,
      availableOemsByCountry,
    );
  }, [dealerCode, dealerName, filters, dashboardDealers, availableOemsByCountry]);

  return (
    <>
      <Select
        className={classnames(props.className, classes.dealerSelection)}
        value={{ value: selected.dealer, label: selected.dealer }}
        styles={{
          groupHeading: base => {
            return {
              ...base,
              fontWeight: "bold",
              fontSize: 10,
              color: "#333",
            };
          },
        }}
        onChange={(val: any) => {
          if (onChange) {
            onChange(val);
          }
          setDealer(liveFilteredDealers.find(d => d.dealer == val.value));
        }}
        options={[
          { value: "disabled", label: DropdownDescription["DealerSelect"], isdisabled: true },
          { label: "Active Dealers", options: [...liveFilteredDealers.filter(d => d.active).map(opt => ({ value: opt.dealer, label: opt.dealer }))] },
          { label: "Inactive Dealers", options: [...liveFilteredDealers.filter(d => !d.active).map(opt => ({ value: opt.dealer, label: opt.dealer }))] },
        ]}
      />
      <Button variant="contained" className={classes.button} onClick={() => toggleModal(true)}>
        ...
      </Button>
      <Modal open={modalOpen} onClose={() => toggleModal(false)}>
        <DealerFilter
          selectedDealers={[]}
          selectDealer={() => {}}
          selectAll={() => {}}
          hideTitle={false}
          setFilterSet={setFilterSet}
          hideButtons={false}
          shrinkInputLabels={false}
          className=""
          classes={classes}
          filteredDealers={filteredDealers}
          liveFilteredDealers={liveFilteredDealers}
          dealerCode={dealerCode}
          dealerName={dealerName}
          setDealerCode={setDealerCode}
          setDealerName={setDealerName}
          filters={filters}
          updateFilter={updateFilter}
          toggleModal={toggleModal}
          setFilters={setFilters}
          filterSet={filterSet}
          selection="dashboard"
          setDealer={setDealer}
        />
      </Modal>
    </>
  );
};

export const DealerSelection = withStyles(styles)(DealerSelectionUnstlyed);
