import AppBar from "@material-ui/core/AppBar";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React, { useState, useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import logo from "./logo.png";
import { MenuItem } from "./MenuItem";
import classnames from "classnames";
import MuiMenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import { UserContext } from "../contexts/UserContext";
import Typography from "@material-ui/core/Typography";
import Key from "@material-ui/icons/VpnKey";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { DashboardDrawer } from "./DashboardDrawer";
import { margin } from "../style/theme";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Burger from "@material-ui/icons/Menu";
import { TabAccessContext } from "../contexts/TabAccessContext";
import { Loading } from "../components/Loading";
import MMenuItem from "@material-ui/core/MenuItem";
import { AdminMenu } from "./AdminMenu";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { AdminMenuItem } from "./AdminMenuItem";
import Divider from "@material-ui/core/Divider";
import Popover from "@material-ui/core/Popover";
import { AdminMenuItemWithChild } from "./AdminMenuItemWithChild";
import { SelectAdminPagesContext } from "../contexts/SelectedAdminPagesContext";
import Dashboard from "@material-ui/icons/Dashboard";
import Settings from "@material-ui/icons/Settings";
import Terms from "@material-ui/icons/ViewAgendaRounded";
import { FormattedMessage, useIntl } from "react-intl";
import { MenuComp } from "./MenuComp";
import { LanguageContext } from "../contexts/LanguageContext";
import TranslateIcon from "@material-ui/icons/Translate";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { translation } from "../translations/Translations";
import { fixedOemLocations, dynamicOemLocations } from "../charts/DashboardTabs";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Table, TableCell as MuiTableCell, TableRow, TextField } from "@material-ui/core";
import { changePdfPassword } from "../lib/api/analysis";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFFFFF",
      width: "100%",
    },
    mobileMenu: {
      display: "block",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    appBar: {
      backgroundColor: "#FFFFFF",
      color: "#555",
      border: "none",
      width: "100%",
      maxWidth: margin.width,
      overflow: "hidden",
      margin: "0 auto",
    },
    logo: {
      padding: `0 ${theme.spacing.unit}px`,
      lineHeight: 0,
      paddingBottom: "2px",

      "& img": {
        width: "110px",
        height: "50px",
      },
      "@media (max-width:380px)": {
        width: "150px",
      },
      "@media (min-width:380px) and (max-width:420px)": {
        width: "200px",
      },
    },
    main: {},
    menu: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      margin: "auto",
      marginTop: `1em`,
    },
    mItem: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    userPopup: {
      zIndex: 9999999,
    },
    profile: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flexDirection: "row",
      padding: "5px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "rgba(0, 0, 0, 0.25)",
        borderRadius: "6px",
      },
    },
    flex: { flex: "1 1 auto" },
    menuItem: {
      width: "150px",
      // height: "50px",
    },
    menuItemLabel: {
      width: "80px",
    },
    avatar: {
      borderRadius: "50%",
      overflow: "hidden",
      width: 24,
      height: 24,
      backgroundColor: "#333",
      alignItems: "center",
      justifyContent: "center",
    },
    image: {
      margin: 10,
    },
    adminItem: {
      backgroundColor: "#555",
    },
    profileName: {
      marginRight: "10px",
      marginLeft: "5px",
      fontSize: "11px",
      "@media (max-width:380px)": {
        marginRight: "5px",
        marginLeft: "0px",
        fontSize: "12px",
      },
      "@media (min-width:380px) and (max-width:420px)": {
        marginRight: "5px",
        marginLeft: "5px",
        fontSize: "12px",
      },
    },
    profileImage: {
      height: "25px",
      width: "25px",
    },
    filterburger: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    burger: {
      color: "#555",
      margin: theme.spacing.unit * 2,
      cursor: "pointer",
    },
    hideMenuItem: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
      [theme.breakpoints.up("xl")]: {
        display: "flex",
      },
    },
    menuIcon: {
      color: "#555",
    },
    subMenuItem: {
      "&:hover": {
        color: "#000",
      },
    },
    languageArrow: {
      color: "#555",
      margin: "auto",
    },
    passwordField: {
      width: "20em",
    },
  });

export type DrawerProps = {} & WithStyles<typeof styles>;

type LocationType = {
  url: string;
  to: string;
  label: string;
  count: number;
  className: string;
  children: LocationChildrenType[];
  placeholder?: boolean;
};

type LocationChildrenType = {
  to: string;
  id: string;
  label: string;
  count: number;
  placeholder?: boolean;
  children: any[];
};

export const adminLocations = {
  users: {
    url: "/admin/users",
    to: "/admin/users/manage",
    label: "admin.users.header",
    className: "",
    count: 0,
    children: [
      { to: "/admin/users/manage", label: "admin.manage.subheader", id: "editUsers", count: 0, children: [] },
      { to: "/admin/users/roles", label: "admin.roles.subheader", id: "editRoles", count: 0, children: [] },
    ],
  },
  dealers: {
    url: "/admin/dealers",
    to: "/admin/dealers/manage",
    label: "admin.dealers.header",
    count: 0,
    className: "",
    children: [
      { to: "/admin/dealers/manage", label: "admin.manage.subheader", id: "editDealers", count: 0, children: [] },
      { to: "/admin/dealers/mappings", label: "admin.mappings.subheader", id: "dealerMapping", count: 0, children: [] },
      { to: "/admin/dealers/dealerset", label: "admin.dealer.set.subheader", id: "editDealerSet", count: 0, children: [] },
      { to: "/admin/dealers/datareload", label: "admin.data.reload.subheader", id: "dataReload", count: 0, children: [] },
      { to: "/admin/dealers/submissionreload", label: "Submission Reload", id: "dataReload", count: 0, children: [] },
    ],
  },
  dealerGroups: {
    url: "/admin/dealergroups",
    to: "/admin/dealergroups/manage",
    label: "admin.dealer.groups.header",
    className: "",
    count: 0,
    children: [{ to: "/admin/dealergroups/manage", label: "admin.manage.subheader", id: "editDealerGroups", count: 0, children: [] }],
  },
  oemsetup: {
    url: "/admin/oemsetup",
    to: "/admin/oemsetup/manage",
    label: "admin.oem.setup.header",
    className: "",
    count: 0,
    children: [
      { to: "/admin/oemsetup/manage", label: "admin.manage.subheader", id: "editDistributors", count: 0, children: [] },
      { to: "/admin/oemsetup/inputtemplates", label: "admin.input.templates.subheader", id: "inputTemplates", count: 0, children: [] },
    ],
  },
  uploadschedule: {
    url: "/admin/uploadschedule",
    to: "/admin/uploadschedule/manage",
    label: "admin.upload.schedule.header",
    className: "",
    count: 0,
    children: [{ to: "/admin/uploadschedule/manage", label: "admin.manage.subheader", id: "uploadSchedule", count: 0, children: [] }],
  },
  dataadmin: {
    url: "/admin/dataadmin",
    to: "/admin/dataadmin/uploadmarketsharedata",
    label: "admin.data.admin.header",
    className: "",
    count: 0,
    children: [
      {
        to: "/admin/dataadmin/uploadmarketsharedata",
        label: "admin.upload.market.share.data.subheader",
        id: "cube_market_shares",
        count: 0,
        children: [],
      },
      { to: "/admin/dataadmin/marketconsolidation", label: "admin.market.consolidation.header", id: "cube_marketshare", count: 0, children: [] },
      {
        to: "/admin/dataadmin/automated-consolidation",
        label: "admin.automated.consolidation.subheader",
        id: "cube_marketshare$marketcons",
        count: 0,
        children: [],
      },
      { to: "/admin/dataadmin/benchmarks", label: "admin.benchmarks.header", id: "cube_benches", count: 0, children: [] },
      { to: "/admin/dataadmin/forecasts", label: "admin.forecasts.subheader", id: "cube_forecasts", count: 0, children: [] },
      { to: "/admin/dataadmin/cubereload", label: "Cube Reload", id: "cube_reload", count: 0, children: [] },
    ],
  },
  systemadmin: {
    url: "/admin/systemadmin",
    to: "/admin/systemadmin/systemadmin",
    label: "admin.system.admin.header",
    className: "",
    count: 0,
    children: [
      { to: "/admin/systemadmin/systemadmin", label: "admin.system.admin.subheader", id: "cube_admin$settings$index", count: 0, children: [] },
      {
        to: "/admin/systemadmin/ratioengine/",
        label: "admin.ratio.engine.header",
        id: "RatioEngine",
        count: 0,
        children: [
          { to: "/admin/systemadmin/ratioengine/coaindicators", label: "admin.coa.indicators.subheader", id: "accounts", count: 0, children: [] },
          { to: "/admin/systemadmin/ratioengine/ratios", label: "admin.ratios.subheader", id: "maintainRatios", count: 0, children: [] },
          {
            to: "/admin/systemadmin/ratioengine/qualitygates",
            label: "admin.quality.gates.subheader",
            id: "maintainQualityGates",
            count: 0,
            children: [],
          },
          { to: "/admin/systemadmin/ratioengine/mastercoa", label: "admin.master.coa.subheader", id: "cube_accounts", count: 0, children: [] },
          { to: "/admin/systemadmin/ratioengine/measure", label: "admin.measure.subheader", id: "cube_measures", count: 0, children: [] },
          {
            to: "/admin/systemadmin/ratioengine/basemeasure",
            label: "admin.base.measure.subheader",
            id: "cube_base_measures",
            count: 0,
            children: [],
          },
        ],
      },
      {
        to: "/admin/systemadmin/reportwriter",
        label: "admin.report.writer.subheader",
        count: 0,
        children: [
          { to: "/admin/systemadmin/reportwriter/reports", label: "admin.report.subheader", id: "cube_reports", count: 0, children: [] },
          {
            to: "/admin/systemadmin/reportwriter/dashboards",
            label: "admin.dashboards.subheader",
            id: "cube_dashboards",
            count: 0,
            children: [],
          },
          { to: "/admin/systemadmin/reportwriter/datasets", label: "admin.datasets.subheader", id: "cube_datasets", count: 0, children: [] },
          {
            to: "/admin/systemadmin/reportwriter/senarioplanner",
            label: "admin.senarioplanner.subheader",
            id: "cube_what_if$setup",
            count: 0,
            children: [],
          },
          { to: "/admin/systemadmin/reportwriter/report", label: "admin.reportmenu.subheader", id: "editReport", count: 0, children: [] },
        ],
      },
      {
        to: "/admin/systemadmin/batchserverthresholds",
        label: "admin.batch.server.thresholds.subheader",
        id: "cube_batch_thresholds$new",
        count: 0,
        children: [],
      },
      { to: "/admin/systemadmin/audit", label: "admin.audit.subheader", id: "cube_audit", count: 0, children: [] },
      {
        to: "/admin/systemadmin/options",
        label: "admin.options.subheader",
        id: "Options",
        count: 0,
        children: [
          // { to: "/admin/systemadmin/options/properties", label: "admin.properties.subheader", id: "editProperties", count: 0, children: [] },
          // {
          //   to: "/admin/systemadmin/options/olapproperties",
          //   label: "admin.cube.properties.subheader",
          //   id: "cube_admin$configurations$new",
          //   count: 0,
          //   children: [],
          // },
          { to: "/admin/systemadmin/options/logentries", label: "admin.log.entries.subheader", id: "log", count: 0, children: [] },
          { to: "/admin/systemadmin/options/sqlstatement", label: "admin.sql.statement.subheader", id: "sqlStatement", count: 0, children: [] },
          {
            to: "/admin/systemadmin/options/currency",
            label: "admin.currency.subheader",
            id: "cube_utils_currency_matrix",
            count: 0,
            children: [],
          },
          { to: "/admin/systemadmin/options/masterdata", label: "admin.master.data.subheader", id: "masterData", count: 0, children: [] },
        ],
      },
    ],
  },
};

export const locations = {
  dashboard: {
    url: "/dashboard",
    to: "/dashboard",
    label: "Dashboard",
    count: 0,
    className: "joyride-start",
    children: [
      { to: "/dashboard", label: "Home", id: "Dashboard / Home", count: 0, children: [] },
      { to: "/dashboard/exec", label: "Executive", id: "Dashboard / Executive Summary", count: 0, children: [] },
      { to: "/dashboard/balance-sheet", label: "Balance Sheet", id: "Dashboard / Balance Sheet", count: 0, children: [] },
      { to: "/dashboard/new", label: "NV F&I", id: "Dashboard / New", count: 0, children: [] },
      { to: "/dashboard/used", label: "UV F&I", id: "Dashboard / Used", count: 0, children: [] },
      { to: "/dashboard/parts", label: "Parts", id: "Dashboard / Parts", count: 0, children: [] },
      { to: "/dashboard/service", label: "Service", id: "Dashboard / Service", count: 0, children: [] },
      { to: "/dashboard/bodyshop", label: "Body Shop", id: "Dashboard / Body Shop", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  groupDashboard: {
    url: "/group-dashboard",
    to: "/group-dashboard/exec",
    label: "Group Dashboard",
    count: 0,
    className: "",
    children: [
      { to: "/group-dashboard/exec", label: "Executive", id: "Group Dashboard / Executive Summary", count: 0, children: [] },
      { to: "/group-dashboard/balancesheet", label: "Balance Sheet", id: "Group Dashboard / Balance Sheet", count: 0, children: [] },
      { to: "/group-dashboard/new", label: "NV F&I", id: "Group Dashboard / New", count: 0, children: [] },
      { to: "/group-dashboard/used", label: "UV F&I", id: "Group Dashboard / Used", count: 0, children: [] },
      { to: "/group-dashboard/parts", label: "Parts", id: "Group Dashboard / Parts", count: 0, children: [] },
      { to: "/group-dashboard/service", label: "Service", id: "Group Dashboard / Service", count: 0, children: [] },
      { to: "/group-dashboard/bodyshop", label: "Body Shop", id: "Group Dashboard / Body Shop", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  OEMDashboard: {
    url: "/oem",
    to: "/oem/fixed/home",
    label: "OEM Dashboard",
    count: 0,
    className: "",
    children: [
      {
        to: "/oem/fixed/home",
        label: "Fixed",
        id: "OEM Dashboard / Fixed",
        count: 0,
        children: [],
        placeholder: true,
      },
      {
        to: "/oem/dynamic/home",
        label: "Dynamic",
        id: "OEM Dashboard / Dynamic",
        count: 0,
        children: [],
        placeholder: true,
      },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  customDashboard: {
    url: "/custom",
    to: "/custom/dealer",
    label: "Custom Dashboard",
    count: 0,
    className: "joyride-start",
    children: [
      { to: "/custom/dealer", label: "Dealer", id: "Dashboard / Custom", count: 0, children: [] },
      { to: "/custom/group", label: "Group", id: "Group Dashboard / Custom", count: 0, children: [] },
      { to: "/custom/distribution", label: "Distribution", id: "Distribution Dashboard / Custom", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  reports: {
    url: "/reports",
    to: "/reports/Standard Reports",
    label: "Reports",
    count: 0,
    className: "",
    children: [
      {
        to: "/reports/Standard Reports",
        label: "Standard Reports",
        id: "Reports / Standard Reports",
        count: 0,
        children: [],
      },
      { to: "/reports/OEM Reports", label: "OEM Reports", id: "Reports / OEM Reports", count: 0, children: [] },
      {
        to: "/reports/Dealer Insight",
        label: "Dealer Insight",
        id: "Reports / Dealer Insight",
        count: 0,
        children: [
          {
            to: "/reports/Dealer Insight/Performance Insight",
            label: "Performance Insight",
            id: "Reports / Dealer Insight / Performance Insight",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Dealer Insight/Project ROS",
            label: "Project ROS",
            id: "Reports / Dealer Insight / Project ROS",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Dealer Insight/Opportunity Report",
            label: "Opportunity Report",
            id: "Reports / Dealer Insight / Opportunity Report",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Dealer Insight/MRA Analysis",
            label: "MRA Analysis",
            id: "Reports / Dealer Insight / MRA Analysis",
            count: 0,
            children: [],
          },
        ],
      },
      {
        to: "/reports/Network Insight",
        label: "Network Insight",
        id: "Reports / Network Insight",
        count: 0,
        children: [
          {
            to: "/reports/Network Insight/Performance Insight",
            label: "Performance Insight",
            id: "Reports / Network Insight / Performance Insight",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Network Insight/Project ROS",
            label: "Project ROS",
            id: "Reports / Network Insight / Project ROS",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Network Insight/Opportunity Report",
            label: "Opportunity Report",
            id: "Reports / Network Insight / Opportunity Report",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Network Insight/MRA Analysis",
            label: "MRA Analysis",
            id: "Reports / Network Insight / MRA Analysis",
            count: 0,
            children: [],
          },
        ],
      },
      {
        to: "/reports/Other BM Tools",
        label: "Other BM Tools",
        id: "Reports / Other BM Tools",
        count: 0,
        children: [],
      },
      { to: "/reports/Batch Server", label: "Batch Server", id: "Reports / Batch Server", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  submissions: {
    url: "/submissions",
    label: "Submissions",
    to: "/submissions/upload-schedule",
    count: 0,
    className: "",
    children: [
      { to: "/submissions/upload-schedule", label: "Upload Schedule", id: "Submission / Upload Schedule", count: 0, children: [] },
      {
        to: "/submissions/templates",
        label: "Download Templates",
        id: "Submission / Download Templates",
        count: 0,
        children: [],
      },
      { to: "/submissions/submit", label: "Submit Data", id: "Submission / Submit Data", count: 0, children: [] },
      { to: "/submissions/submission-status", label: "Submission Status", id: "Submission / Submission Status", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  insights: {
    url: "/insights",
    to: "/insights/data-submissions/data-preperation",
    label: "Insights",
    count: 0,
    className: "",
    children: [
      {
        to: "/insights/data-submissions/data-preperation",
        label: "Data Preparation",
        id: "Insights / Data Preparation",
        count: 0,
        children: [],
      },
      {
        to: "/insights/data-submissions/data-interpretation",
        label: "Data Interpretation",
        id: "Insights / Interpretation",
        count: 0,
        children: [],
      },
      {
        to: "/insights/data-submissions/bm-process",
        label: "BM Process",
        id: "Insights / BM Process",
        count: 0,
        children: [],
      },
      {
        to: "/insights/data-submissions/industry-insights",
        label: "Industry Insights",
        id: "Insights / Industry Insights",
        count: 0,
        children: [],
      },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  actionPlan: {
    url: "/action-plan",
    to: "/action-plan/New",
    label: "Action Plan",
    count: 0,
    className: "joyride-start",
    children: [
      { to: "/action-plan/new", label: "New", id: "Action Plan / New", count: 0, children: [] },
      // { to: "/action-plan/Parts", label: "Parts", id: "Action Plan / Parts", count: 0, children: [] },
      // { to: "/action-plan/Used", label: "Used", id: "Action Plan / Used", count: 0, children: [] },
      // { to: "/action-plan/Service", label: "Service", id: "Action Plan / Service", count: 0, children: [] },
      // { to: "/action-plan/Body Shop", label: "Body Shop", id: "Action Plan / Body Shop", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
};

type ParentMenuLocationsType = {
  url: string;
  to: string;
  label: string;
  count: number;
  className: string;
  icon: string;
  children: ChildrenMenuLocationsType[];
  firstTab(availableLocations: string[], location: LocationType): void;
};

type ChildrenMenuLocationsType = {
  to: string;
  label: string;
  id: string;
  count: number;
  children: ChildrenMenuLocationsType[];
};

export const menuLocations: { [index: string]: ParentMenuLocationsType } = {
  notifications: {
    url: "/notifications",
    to: "/notifications",
    label: "notifications.label",
    count: 0,
    className: "",
    icon: "message",
    children: [{ to: "/notifications", label: "notifications.messages.subheader", id: "Notifications / Messages", count: 0, children: [] }],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  about: {
    url: "/about",
    to: "/about/msxi",
    label: "about.label",
    count: 0,
    className: "",
    icon: "librarybooks",
    children: [
      { to: "/about/msxi", label: "about.msxi.bm.solution.subheader", id: "About / About MSXI BM Solution", count: 0, children: [] },
      { to: "/about/meet-the-team", label: "about.meet.the.team.subheader", id: "About / Meet the Team", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
};

const TruncatedText: React.SFC<{ className?: string; value: string; greaterThan?: number; truncateAter?: number }> = ({
  className,
  value,
  greaterThan = 7,
  truncateAter = 4,
}) => {
  if (value.length > greaterThan) {
    return (
      <Tooltip title={value}>
        <span className={className}>{`${value.substr(0, truncateAter)}...`}</span>
      </Tooltip>
    );
  }
  return <span className={className}>{value}</span>;
};

const defaultRoute = (availableLocations: string[], location: LocationType) => {
  const childLocations = location.label == "OEM Dashboard" ? [...location.children, ...fixedOemLocations, ...dynamicOemLocations] : location.children;
  const firstTab = childLocations
    .filter(tabLocation =>
      availableLocations.find(item => {
        if (tabLocation.id == "Reports / Dealer Insight" && item.includes("Reports / Dealer Insight")) {
          return true;
        }
        if (tabLocation.id == "Reports / Network Insight" && item.includes("Reports / Network Insight")) {
          return true;
        }
        return item == tabLocation.id;
      }),
    )
    .filter(t => !(t as any).placeholder);

  if (firstTab.length > 0) {
    return firstTab[0].to;
  }
  return "/";
};

type adminLocationsType = {
  to: string;
  url: string;
  label: string;
  count: number;
  className: string;
  hasPermission?: boolean;
  children: adminLocationsType[];
};

type DropdownMenuType = {
  name: string;
  link: string;
  icon: string;
  mobile: boolean;
  visible: boolean;
};

type TabAccessType = {
  tabId: number;
  name: string;
};

const UnstyledLayout: React.FunctionComponent<DrawerProps> = props => {
  const { classes, children } = props;
  const [showLogout, setShowLogout] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [logoutAnchor, setLogoutAnchor] = useState(null);
  const [languageAnchor, setLanguageAnchor] = useState(null);
  const [showLanguageOptions, setShowLanguageOptions] = useState(null);
  const { user, loading } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuChildren, setMenuChildren] = useState([]);

  const [subMenuChildren, setSubMenuChildren] = useState([]);
  const [anchorElChildren, setAnchorElChildren] = React.useState<null | HTMLElement>(null);

  const [settingsAnchor, setSettingsAnchor] = useState(null);
  const [showSettingsOptions, setShowSettingsOptions] = useState(null);

  const [openChangePass, setOpenChangePass] = React.useState(false);
  const [pdfPassword, setPdfPassword] = useState<string>("");
  const [confirmPdfPassword, setConfirmPdfPassword] = useState<string>("");
  const [pdfPassError, setPdfPassError] = useState<string | undefined>(undefined);

  const { language, updateLanguageConfig, languageOptions } = useContext(LanguageContext);

  // Check if the user has the required permission to delete/create new notifications
  // Permissions for the admin items
  const { getPermissions, loading: permissionsLoading } = React.useContext(PermissionsContext);
  const permissionsData = getPermissions();

  const intl = useIntl();

  const handlePasswordChange = () => {
    //validate password
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    if (!pattern.test(pdfPassword)) {
      setPdfPassError(intl.formatMessage({ id: "password.length.error" }));
      return;
    } else {
      setPdfPassError(undefined);
    }

    //confirm password
    if (pdfPassword != confirmPdfPassword) {
      setPdfPassError(intl.formatMessage({ id: "password.confirmation.error" }));
      return;
    } else {
      setPdfPassError(undefined);
    }
    //submit
    changePdfPassword(pdfPassword);
    setOpenChangePass(false);
  };

  const addPermissionsToChildren = (items, child) => {
    const hasPermission = !!permissionsData.find(perm => perm.moduleName == child.id && (perm.canRead || perm.canExec || perm.canWrite));
    items.children.push({
      ...child,
      hasPermission: child.id === "cube_reload" ? user.reloadAccess : hasPermission,
      ...(child.children || []).reduce(addPermissionsToChildren, {
        count: 0,
        children: [],
      }),
    });
    items.count = hasPermission ? items.count + 1 : items.count;
    return items;
  };

  const addPermissions = adminLocations => {
    return Object.entries(adminLocations).reduce((locations, [location, locationConfig]: any) => {
      locations[location] = {
        ...locationConfig,
        ...locationConfig.children.reduce(addPermissionsToChildren, {
          count: 0,
          children: [],
        }),
      };
      return locations;
    }, {});
  };
  const adminLocationsWithPermissions = addPermissions(adminLocations);
  const { tabs: accessTabs, loading: accessTabsLoading } = React.useContext(TabAccessContext);

  if (!accessTabsLoading.loaded || !permissionsLoading.loaded) {
    return <Loading />;
  }

  const availLocations: string[] = Object.values(accessTabs).map((tab: any) => tab.name);

  const menuLocationPermissions = (which: string): boolean => {
    return (
      menuLocations[which].children.filter((tabLocation: ChildrenMenuLocationsType) => availLocations.find((item: string) => item == tabLocation.id)).length > 0
    );
  };

  const v1TermsRoute = {
    asean: "esosv2asean",
    australia: "esosv2aus",
    newzealand: "esosv2newzealand",
    china: "esosv2china",
    india: "esosv2india",
    southafrica: "esosv2sa",
  };

  const dropdownMenuItems: DropdownMenuType[] = [
    {
      mobile: true,
      name: "menu.filters.button",
      link: "/filters",
      icon: "filteredList",
      visible: !location.pathname.includes("/filters") && user.usertype !== "D",
    },
    {
      mobile: false,
      name: "menu.profile.button",
      link: "/profile",
      icon: "user",
      visible: !location.pathname.includes("/profile"),
    },
    {
      mobile: false,
      name: "menu.admin.button",
      link: "/admin",
      icon: "settings",
      visible: availLocations.includes("Admin / Home") && !location.pathname.includes("/admin"),
    },
    {
      mobile: false,
      name: "notifications.label",
      link: "/notifications",
      icon: "message",
      visible: menuLocationPermissions("notifications") && !location.pathname.includes("/notifications"),
    },
    {
      mobile: false,
      name: "about.label",
      link: "/about/msxi",
      icon: "librarybooks",
      visible: menuLocationPermissions("about") && !location.pathname.includes("/about"),
    },
    // { mobile: false, name: "Todo List", link: "/todolist", icon: "checkbox", visible: true },
  ];

  return (
    <div className={classes.root}>
      <Dialog onClose={() => setOpenChangePass(false)} aria-labelledby="customized-dialog-title" open={openChangePass}>
        <DialogTitle id="customized-dialog-title">
          <FormattedMessage id={"change.password.header"} />
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableRow>
              <TableCell>
                <TextField
                  error={!!pdfPassError}
                  id="outlined-password-input"
                  label={intl.formatMessage({ id: "password.label" })}
                  type="password"
                  variant="outlined"
                  className={classes.passwordField}
                  onChange={e => setPdfPassword(e.target.value)}
                />
              </TableCell>
            </TableRow>
            {!!pdfPassError && (
              <TableRow>
                <TableCell>
                  <Typography color={"error"}>{pdfPassError}</Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <TextField
                  id="outlined-confirm-password-input"
                  label={intl.formatMessage({ id: "confirm.password.label" })}
                  type="password"
                  variant="outlined"
                  className={classes.passwordField}
                  onChange={e => setConfirmPdfPassword(e.target.value)}
                />
              </TableCell>
            </TableRow>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              handlePasswordChange();
            }}
            color="primary"
          >
            Ok
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setPdfPassword("");
              setConfirmPdfPassword("");
              setPdfPassError(undefined);
              setOpenChangePass(false);
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <DashboardDrawer open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} className={classes.mobileMenu} />
      <AppBar position="static" className={classes.appBar}>
        <div className={classnames(classes.menu)}>
          <div className={classes.filterburger}>
            <Burger className={classes.burger} onClick={() => setMenuOpen(!menuOpen)} />
          </div>
          <div className={classes.logo}>
            <a href="/dashboard">
              <img src={logo} />
            </a>
          </div>
          <Switch>
            <Route path="/filters" render={() => null} />
            <Route path="/profile" render={() => null} />
            {Object.values(menuLocations).map(location => {
              return location.children.map(childLocation => {
                return <Route path={childLocation.to} render={() => null} />;
              });
            })}
            <Route
              path="/admin/:rest?"
              render={({ match }) => {
                return Object.values(adminLocationsWithPermissions).map((location: adminLocationsType, indx: number) => {
                  return (
                    <React.Fragment key={indx}>
                      <span key={"menukey1"}>
                        {location.children.filter(a => a.hasPermission || a.count > 0).length > 0 && (
                          <AdminNavMenu
                            match={match}
                            location={location}
                            classes={classes}
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            setMenuChildren={setMenuChildren}
                          />
                        )}
                      </span>
                      <span key={"menukey2"}>
                        {Boolean(anchorEl) && (
                          <AdminMainMenu
                            match={match}
                            classes={classes}
                            location={location}
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            menuChildren={menuChildren}
                            anchorElChildren={anchorElChildren}
                            setSubMenuChildren={setSubMenuChildren}
                            setAnchorElChildren={setAnchorElChildren}
                          />
                        )}
                      </span>
                      <span key={"menukey3"}>
                        {Boolean(anchorElChildren) && (location.count > 0 || location.hasPermission) && (
                          <AdminSubMenu
                            match={match}
                            classes={classes}
                            location={location}
                            setAnchorEl={setAnchorEl}
                            subMenuChildren={subMenuChildren}
                            anchorElChildren={anchorElChildren}
                            setAnchorElChildren={setAnchorElChildren}
                          />
                        )}
                      </span>
                    </React.Fragment>
                  );
                });
              }}
            />
            <Route
              render={({ match }) => {
                return Object.values(locations).map(location => {
                  if (location.label !== "Dashboard") {
                    const childLocations =
                      location.label == "OEM Dashboard" ? [...location.children, ...fixedOemLocations, ...dynamicOemLocations] : location.children;

                    if (
                      childLocations.filter(tabLocation =>
                        availLocations.find(item => {
                          if (tabLocation.id == "Reports / Dealer Insight" && item.includes("Reports / Dealer Insight")) {
                            return true;
                          }
                          if (tabLocation.id == "Reports / Network Insight" && item.includes("Reports / Network Insight")) {
                            return true;
                          }
                          return item == tabLocation.id;
                        }),
                      ).length > 0
                    ) {
                      return (
                        <MenuItem
                          to={location.firstTab(availLocations, location)}
                          active={match.url.includes(location.url)}
                          className={classnames(location.className, classes.mItem)}
                          count={location.count}
                          key={location.firstTab(availLocations, location)}
                        >
                          {translation[location.label] ? intl.formatMessage({ id: translation[location.label] }) : location.label}
                        </MenuItem>
                      );
                    }
                    return <Redirect to="/dashboard" />;
                  }

                  return (
                    <MenuItem
                      to={location.to}
                      active={match.url.includes(location.url)}
                      className={classnames(location.className, classes.mItem)}
                      count={location.count}
                      key={location.to}
                    >
                      {translation[location.label] ? intl.formatMessage({ id: translation[location.label] }) : location.label}
                    </MenuItem>
                  );
                });
              }}
            />
          </Switch>
          <div className={classes.flex} />
          <div
            className={classes.profile}
            onClick={e => {
              setShowLogout(true);
              setLogoutAnchor(e.currentTarget);
            }}
          >
            <Typography className={classes.profileName}>
              <TruncatedText truncateAter={10} greaterThan={13} value={`${user.firstname} ${user.lastname}`} />
            </Typography>
            <Popper anchorEl={languageAnchor} open={showLanguageOptions} transition className={classes.userPopup} placement="right-start">
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={() => setShowLanguageOptions(false)}>
                      <MenuList>
                        {languageOptions.map(lang => (
                          <MuiMenuItem
                            key={lang.value}
                            className={classes.menuItem}
                            selected={lang.value === language}
                            onClick={() => {
                              updateLanguageConfig(lang.value);
                              setShowLanguageOptions(false);
                            }}
                          >
                            <Typography>{lang.label}</Typography>
                          </MuiMenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Popper anchorEl={settingsAnchor} open={showSettingsOptions} transition className={classes.userPopup} placement="right-start">
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={() => setShowSettingsOptions(false)}>
                      <MenuList>
                        <MuiMenuItem
                          className={classes.menuItem}
                          onClick={() => {
                            setOpenChangePass(true);
                            setShowSettingsOptions(false);
                          }}
                        >
                          <Typography>
                            <FormattedMessage id={"settings.pdf.change.password"} />
                          </Typography>
                        </MuiMenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Popper anchorEl={logoutAnchor} open={showLogout} transition className={classes.userPopup} placement="bottom-end">
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={() => setShowLogout(false)}>
                      <MenuList>
                        {dropdownMenuItems.find(dropdown => location.pathname.includes(dropdown.link)) && (
                          <MuiMenuItem component="a" href={"/dashboard"} className={classes.menuItem} onClick={() => setShowLogout(false)}>
                            <ListItemIcon>
                              <Dashboard />
                            </ListItemIcon>
                            <MenuItemLabel classes={classes}>
                              <FormattedMessage id={"dashboard.dashboard.header"} />
                            </MenuItemLabel>
                          </MuiMenuItem>
                        )}
                        {dropdownMenuItems
                          .filter((item: DropdownMenuType) => item.visible)
                          .map((dropdownItem: DropdownMenuType, index: number) => (
                            <MenuComp
                              key={index}
                              link={dropdownItem.link}
                              icon={dropdownItem.icon}
                              name={dropdownItem.name}
                              mobile={dropdownItem.mobile}
                              visible={dropdownItem.visible}
                            />
                          ))}
                        <MuiMenuItem
                          className={classes.menuItem}
                          onClick={e => {
                            setLanguageAnchor(e.currentTarget);
                            setShowLanguageOptions(true);
                          }}
                        >
                          <ListItemIcon>
                            <TranslateIcon />
                          </ListItemIcon>
                          <MenuItemLabel classes={classes}>
                            <FormattedMessage id="language.label" />
                          </MenuItemLabel>
                          <span className={classes.languageArrow}>
                            <ChevronRightIcon />
                          </span>
                        </MuiMenuItem>
                        <MuiMenuItem
                          className={classes.menuItem}
                          onClick={e => {
                            setSettingsAnchor(e.currentTarget);
                            setShowSettingsOptions(true);
                          }}
                        >
                          <ListItemIcon>
                            <Settings />
                          </ListItemIcon>
                          <MenuItemLabel classes={classes}>
                            <FormattedMessage id="settings.label" />
                          </MenuItemLabel>
                          <span className={classes.languageArrow}>
                            <ChevronRightIcon />
                          </span>
                        </MuiMenuItem>
                        <MuiMenuItem
                          component="a"
                          target="_blank"
                          href={`https://${window.location.host.split(".")[0].replace("qa", "")}2.sewellsgroupreporting.com/${
                            v1TermsRoute[window.location.host.split(".")[0].replace("qa", "")]
                          }/help/Terms%20and%20Conditions%20of%20Use%20and%20Privacy%20Policy.pdf`}
                          className={classes.menuItem}
                        >
                          <ListItemIcon>
                            <Terms />
                          </ListItemIcon>
                          <MenuItemLabel classes={classes}>
                            <FormattedMessage id={"dashboard.terms.and.con"} />
                          </MenuItemLabel>
                        </MuiMenuItem>
                        <MuiMenuItem
                          className={classes.menuItem}
                          onClick={() => {
                            setShowLogout(false);
                          }}
                          component="a"
                          href="/api/logout"
                        >
                          <ListItemIcon>
                            <Key />
                          </ListItemIcon>
                          <MenuItemLabel classes={classes}>
                            <FormattedMessage id="menu.logout.button" />
                          </MenuItemLabel>
                        </MuiMenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Grid container justify="center" alignItems="center" className={classes.profileImage}>
              <Avatar className={classes.profileImage} src={`/api/whoami/avatar.jpg?cachebuster=${new Date().toString()}`} />
            </Grid>
          </div>
        </div>
      </AppBar>
      <main className={classes.main}>{children}</main>
    </div>
  );
};

const AdminMainMenu = ({ anchorEl, setAnchorEl, location, setSubMenuChildren, setAnchorElChildren, menuChildren, classes, match, anchorElChildren }) => {
  const { selectedAdminPages, setSelectedAdminPages } = useContext(SelectAdminPagesContext);

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {(menuChildren || []).map((menuChild, i) => {
        return (
          <span key={menuChild.to}>
            <MMenuItem style={{ padding: "5px" }}>
              <div
                aria-owns={anchorElChildren ? "simple-menu2" : undefined}
                onClick={e => {
                  if (menuChild.children.length > 0) {
                    setSubMenuChildren(menuChild.children.filter(a => a.hasPermission || a.count > 0));
                    return setAnchorElChildren(e.currentTarget);
                  }
                  setAnchorEl(null);
                  if (!selectedAdminPages.find(selected => selected.to === menuChild.to)) {
                    setSelectedAdminPages([...selectedAdminPages, menuChild]);
                  }
                }}
                style={{ display: "flex", alignItems: "center" }}
              >
                {menuChild.children.length > 0 ? (
                  <>
                    <AdminMenuItemWithChild
                      active={match.url.includes(menuChild.url)}
                      className={classnames(menuChild.className, menuChild.mItem, classes.subMenuItem)}
                      classes={{
                        active: classes.adminItem,
                      }}
                    >
                      {menuChild.label}
                    </AdminMenuItemWithChild>
                    <span className={classes.menuIcon}>
                      <KeyboardArrowRight style={{ fontSize: "18px" }} />
                    </span>
                  </>
                ) : (
                  <AdminMenuItem
                    to={menuChild.to}
                    active={match.url.includes(menuChild.url)}
                    className={classnames(menuChild.className, classes.mItem, classes.subMenuItem)}
                    classes={{
                      active: classes.adminItem,
                    }}
                    count={0}
                    key={menuChild.to}
                  >
                    {menuChild.label}
                  </AdminMenuItem>
                )}
              </div>
            </MMenuItem>
            {menuChildren.length - 1 != i && <Divider />}
          </span>
        );
      })}
    </Popover>
  );
};

const AdminSubMenu = ({ location, anchorElChildren, setAnchorElChildren, subMenuChildren, match, classes, setAnchorEl }) => {
  const { selectedAdminPages, setSelectedAdminPages } = useContext(SelectAdminPagesContext);

  return (
    <Popover
      anchorEl={anchorElChildren}
      open={Boolean(anchorElChildren)}
      onClose={() => setAnchorElChildren(null)}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      {(subMenuChildren || []).map((subMenuChild, i: number) => (
        <span key={subMenuChild.to}>
          <MMenuItem
            style={{ padding: "5px" }}
            onClick={() => {
              setAnchorEl(null);
              setAnchorElChildren(null);
              if (!selectedAdminPages.find(selected => selected.to === subMenuChild.to)) {
                setSelectedAdminPages([...selectedAdminPages, subMenuChild]);
              }
            }}
          >
            {(subMenuChild.count > 0 || subMenuChild.hasPermission) && (
              <AdminMenuItem
                to={subMenuChild.to}
                active={match.url.includes(subMenuChild.url)}
                className={classnames(subMenuChild.className, classes.mItem, classes.subMenuItem)}
                classes={{
                  active: classes.adminItem,
                }}
                count={0}
                key={subMenuChild.to}
              >
                {subMenuChild.label}
              </AdminMenuItem>
            )}
          </MMenuItem>
          {subMenuChildren.length - 1 != i && <Divider />}
        </span>
      ))}
    </Popover>
  );
};

const AdminNavMenu = ({ location, anchorEl, setAnchorEl, setMenuChildren, match, classes }) => {
  return (
    <div
      key={location.to}
      aria-owns={anchorEl ? "simple-menu" : undefined}
      onClick={e => {
        setAnchorEl(e.currentTarget);
        setMenuChildren(location.children.filter(a => a.hasPermission || a.count > 0));
      }}
    >
      <AdminMenu
        active={match.url.includes(location.url)}
        className={classnames(location.className, classes.mItem)}
        classes={{
          active: classes.adminItem,
        }}
      >
        {location.label}
      </AdminMenu>
    </div>
  );
};

export const MenuItemLabel = ({ classes, children }) => {
  return <Typography className={classes.menuItemLabel}>{children}</Typography>;
};

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

export const Layout = withStyles(styles, { withTheme: true })(UnstyledLayout);
