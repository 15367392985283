import React, { createContext, useState, useMemo } from "react";

const defaultSelectedAdminPages = [];

export type SelectAdminPagesContextState = {
  selectedAdminPages: any[];
  setSelectedAdminPages(value: any[]): void;
};

export const SelectAdminPagesContext = createContext<SelectAdminPagesContextState>({
  selectedAdminPages: defaultSelectedAdminPages,
  setSelectedAdminPages: () => { }
});

export const SelectAdminPagesProvider = ({ children }) => {
  const [selectedAdminPages, setSelectedAdminPages] = useState<any[]>(defaultSelectedAdminPages);

  const value = useMemo(() => ({
    selectedAdminPages,
    setSelectedAdminPages: (value: any[]) => setSelectedAdminPages(value),
  }), [selectedAdminPages]);

  return (
    <SelectAdminPagesContext.Provider value={value}>
      {children}
    </SelectAdminPagesContext.Provider>
  )
}
