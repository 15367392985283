import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    active: {
      backgroundColor: "#555",
      color: "#fff",
      border: "none",
    },
    link: {
      textDecoration: "none",
      color: "inherit",
      fontSize: `${theme.typography.fontSize}px`,
      padding: theme.spacing.unit,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      borderTopRightRadius: "3px",
      borderTopLeftRadius: "3px",
      display: "block",
      width: "150px",
      fontWeight: "lighter",
    },
  });

export type AdminMenuItemProps = {
  active?: boolean;
  count?: number;
  className?: string;
} & WithStyles<typeof styles>;

const AdminMenuItemUnstlyed: React.FunctionComponent<AdminMenuItemProps> = ({ className, classes, active, children, count }) => (
  <Typography
    variant="title"
    color="inherit"
    className={classnames(classes.root, className, {
      [classes.active]: active,
    })}
  >
    <span className={classes.link}>
      <FormattedMessage id={children.toString()} />
    </span>
  </Typography>
);

export const AdminMenuItemWithChild = withStyles(styles)(AdminMenuItemUnstlyed);
