import { request } from "./base";
import { Report, MetricReport } from "./charts";

export type Dashboard = {
  id: string;
  settings: {
    name: string;
    layout: string;
    items: {
      [index: string]: {
        measure: Report | MetricReport;
      };
    };
    types: {
      [index: string]: {
        chartTypes: "bar" | "line";
      };
    };
    periods: {
      [index: string]: {
        periods: "MTD" | "QTD" | "YTD" | "Yearly";
      };
    };
    benchmarks: {
      [index: string]: {
        benchmarks: boolean;
      };
    };
    medians: {
      [index: string]: {
        medians: boolean;
      };
    };
  };
};

export const dashboards = () => request("/user/custom-dashboards").then(res => res.json());

export const updateDashboard = (id: string, config: Dashboard) =>
  request(`/user/custom-dashboards/${id}`, {
    method: "PUT",
    body: JSON.stringify(config),
  }).then(res => res.json());

export const createDashboard = (config: Dashboard) =>
  request(`/user/custom-dashboards`, {
    method: "POST",
    body: JSON.stringify(config),
  }).then(res => res.json());

export const deleteDashboard = (id: string) =>
  request(`/user/custom-dashboards/${id}`, {
    method: "DELETE",
  }).then(res => res.json());
